import { Button } from "@/components/button";
import { Checkbox } from "@/components/inputs";
import { FormModal } from "@/components/modal";
import { InputText } from "@/components/typography";
import { createOnboardingNodeMutation } from "@/lib/queries";
import { useOnboarding } from "@/providers/onboardingProvider";
import { useState } from "react";
import { useMutation } from "urql";
import { create } from "zustand";
import { persist } from "zustand/middleware";

type WorkExperienceModalStateType = {
  open: boolean;
  firstShown: boolean;
  toggleFirstShown: () => void;
  setOpen: (open: boolean) => void;
};

const allowedNodeTypesToMake = [
  "critical-role",
  "original-contribution",
  "high-compensation",
  "award",
  "authorship",
  "membership",
  "judging",
  "press",
] as const;

export const useWorkExperienceModalStore = create(
  persist<WorkExperienceModalStateType>(
    (set) => ({
      open: true,
      firstShown: false,
      toggleFirstShown: () =>
        set((state) => ({ firstShown: !state.firstShown })),
      setOpen: (open: boolean) => set(() => ({ open })),
    }),
    {
      name: "lighthouse-work-experience-modal",
    }
  )
);

export const WorkExperienceIntroModal = () => {
  const { groupNodes, onboardingId, visaClass } = useOnboarding();

  const createNodeMutation = useMutation(createOnboardingNodeMutation)[1];

  const [isCreating, setIsCreating] = useState(false);
  const [error, setError] = useState("");

  const open = useWorkExperienceModalStore((state) => state.open);
  const setOpen = useWorkExperienceModalStore((state) => state.setOpen);
  const firstShown = useWorkExperienceModalStore((state) => state.firstShown);
  const toggleFirstShown = useWorkExperienceModalStore(
    (state) => state.toggleFirstShown
  );

  const nodeTypesNotInCurrentGroup = allowedNodeTypesToMake.filter(
    (nodeType) => !groupNodes.some((group) => group.type === nodeType)
  );

  const [nodeTypesToCreate, setNodeTypesToCreate] = useState<
    (typeof allowedNodeTypesToMake)[number][]
  >([]);

  const hasUserMadeThisNodeType = (nodeType: string) =>
    groupNodes.some((node) => node.type === nodeType && node.customerCreated);

  const createNodes = async () => {
    if (nodeTypesToCreate.length === 0) {
      setOpen(false);
      if (!firstShown) {
        toggleFirstShown();
      }
      return;
    }

    setIsCreating(true);
    for (const type of nodeTypesToCreate) {
      const res = await createNodeMutation({
        input: {
          nodeType: type,
          onboardingId: onboardingId,
        },
      });

      if (res.error) {
        console.error(res.error);
        setError(`Failed to add ${type} to case strategy`);
        setIsCreating(false);
        return;
      }
    }

    setIsCreating(false);
    setOpen(false);
    setNodeTypesToCreate([]);
    if (!firstShown) {
      toggleFirstShown();
    }
  };

  return (
    <FormModal
      open={open || !firstShown}
      onOpenChange={(val) => {
        if (!val) {
          if (!firstShown) {
            toggleFirstShown();
          }
        }
        setOpen(val);
      }}
      title='Intro to "Your Work Experience" step'
    >
      <div className="flex flex-col gap-2 pr-12">
        {visaClass === "o-1a" && (
          <>
            <p>
              <InputText className="text-sm text-grey-300 font-normal">
                The O-1A is a 3-year work visa intended for individuals who have
                demonstrated expertise in a particular field. To be eligible for
                it we look to prepare an application with{" "}
                <span className="text-grey-100">advisory opinion letters </span>
                {/* <span className="text-blue">*</span>  */}
                and evidence supporting{" "}
                <span className="text-grey-100"> USCIS criteria.</span>
                {/* <span className="text-blue">*</span> */}
              </InputText>
            </p>
            <p>
              <InputText className="text-sm text-grey-300 font-normal">
                Our approach in case strategy is to highlight the most
                well-documented and strongest USCIS criteria of the 8, rather
                than including as many as possible.
              </InputText>
            </p>
          </>
        )}

        <div className="flex flex-col py-4 px-6 gap-1 bg-grey-700 rounded-md shadow-border mt-3">
          <InputText className="text-sm font-normal text-grey-100">
            Your case strategy
          </InputText>
          <InputText className="text-sm font-normal text-grey-300">
            Based on what we already know about you, we selected your most
            important achievements that matches USCIS criteria.
          </InputText>

          <div className="flex flex-col my-2 text-sm font-normal gap-2">
            {groupNodes.map((group, index) => (
              <div key={index} className="flex flex-row items-center gap-2">
                <Checkbox checked={true} disabled />

                <span>
                  {group.type
                    .split("-")
                    .map((word) => word[0].toUpperCase() + word.slice(1))
                    .join(" ")}

                  {group.name && group.name.trim() !== "" && (
                    <>
                      {" - "}
                      <span className="font-medium">({group.name})</span>
                    </>
                  )}
                </span>
                {/* <InfoCircledIcon className="text-grey-300" /> */}
              </div>
            ))}
          </div>
          <InputText className="text-sm font-normal text-grey-300">
            If you haven't included information about an achievement earlier and
            you think it is important, you can add it now and we will verify it.
          </InputText>
          <div className="flex flex-col my-2 text-sm font-normal text-grey-300 gap-2">
            {nodeTypesNotInCurrentGroup.map((nodeType, index) => (
              <div key={index} className="flex flex-row items-center gap-1.5">
                <Checkbox
                  checked={
                    hasUserMadeThisNodeType(nodeType) ||
                    nodeTypesToCreate.includes(nodeType)
                  }
                  disabled={hasUserMadeThisNodeType(nodeType)}
                  onCheckedChange={() => {
                    if (nodeTypesToCreate.includes(nodeType)) {
                      setNodeTypesToCreate(
                        nodeTypesToCreate.filter((x) => x !== nodeType)
                      );
                    } else {
                      setNodeTypesToCreate([...nodeTypesToCreate, nodeType]);
                    }
                  }}
                />
                <span>
                  {nodeType
                    .split("-")
                    .map((word) => word[0].toUpperCase() + word.slice(1))
                    .join(" ")}
                </span>
                {/* <InfoCircledIcon className="w-3 h-3" /> */}
              </div>
            ))}
          </div>

          <InputText className="text-sm font-normal text-grey-300">
            You will also be able to create additional sections as you go.
          </InputText>
        </div>
      </div>
      <div className="w-full flex justify-between pr-8 mt-4 items-center">
        <span className="text-negative text-[12px]">{error}</span>

        <Button
          variant="primary"
          onClick={createNodes}
          loading={isCreating}
          disabled={isCreating}
        >
          {nodeTypesToCreate.length === 0 ? "Got it" : "Create"}
        </Button>
      </div>
    </FormModal>
  );
};
