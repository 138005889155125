import { createFileRoute, Outlet } from "@tanstack/react-router";
import { RedirectToSignIn, SignedIn, SignedOut } from "@clerk/clerk-react";
import { getOnboardingContext } from "@/lib/queries";
import { useAdminStore, useUserContext } from "@/lib/hooks";
import * as Sentry from "@sentry/react";
import { useEffect } from "react";

export const Route = createFileRoute("/_portal")({
  loader: async ({ context }) => {
    await context.client.query(getOnboardingContext, {
      contextInput: {
        overrideUserId: useAdminStore.getState().overrideUserId,
      },
    });
  },
  component: () => <InnerLayout />,
});

const SentryUserWrapper = () => {
  const { loggedInUserId, userEntity } = useUserContext();

  useEffect(() => {
    if (loggedInUserId == null) return;
    Sentry.setUser({
      id: loggedInUserId,
      name: userEntity?.legalName,
    });
  }, [loggedInUserId, userEntity]);

  return <Outlet />;
};

const InnerLayout = () => {
  return (
    <div className="flex flex-row max-h-screen h-screen w-screen bg-gray-[#F7F7F7] items-center justify-center overflow-y-scroll">
      <SignedIn>
        <SentryUserWrapper />
      </SignedIn>
      <SignedOut>
        <RedirectToSignIn redirectUrl={window.location.toString()} />
      </SignedOut>
    </div>
  );
};
