import { CreateAdditionalWorkExperienceNodeOption } from "@/components/createAdditionalWorkExperienceNodeOption";
import { Field, OnboardingForm, SubkeyUploadArea } from "@/components/form";
import { TextArea } from "@/components/inputs";
import { useOnboardingNodeData } from "@/lib/hooks";
import { useOnboarding } from "@/providers/onboardingProvider";

export const OriginalContribution = () => {
  const { currentOnboardingNodeId } = useOnboarding();
  const { data, doSetData, customerCreated } = useOnboardingNodeData(
    currentOnboardingNodeId ?? -1
  );

  return (
    <OnboardingForm heading="Your work experience">
      {!customerCreated && (
        <p className="text-[12px] text-grey-300 mr-12 mt-2">
          We believe that you've created something{" "}
          <span className="text-grey-200">
            new and meaningful in your field
          </span>
          . To support criteria, we'll include{" "}
          <span className="text-grey-200">
            details and documentation about a product, technology, or business
            you've built.
          </span>
        </p>
      )}

      <Field
        id="whatMakesYouUnique"
        label="List 4-5 bullets that describe what makes you unique at this company"
      >
        <TextArea
          placeholder="What makes you unique?"
          value={data.whatMakesYouUnique?.value as string}
          onChange={(val) => doSetData("whatMakesYouUnique", val)}
          className="w-full"
        />
      </Field>

      <Field
        id="whatMakesYouImpactful"
        label="List 4-5 bullets that describe what makes you impactful in your field at this company"
      >
        <TextArea
          placeholder="What makes you impactful?"
          value={data.whatMakesYouImpactful?.value as string}
          onChange={(val) => doSetData("whatMakesYouImpactful", val)}
          className="w-full"
        />
      </Field>

      <Field
        id="supportingDocuments"
        label="Upload all of the documents which shows off what you built and its achievements"
        description="All documents are for your visa application only."
      >
        <SubkeyUploadArea
          onboardingNodeId={currentOnboardingNodeId ?? -1}
          subKey="supportingDocuments"
        />
      </Field>

      <Field
        id="additionalComments"
        label="Additional comments"
        description="If you have any additional comments, that you think will be useful for us working on this criteria, please add them here."
      >
        <TextArea
          value={data.additionalComments?.value as string}
          onChange={(val) => doSetData("additionalComments", val)}
          className="w-full"
          charLimit={500}
        />
      </Field>

      {currentOnboardingNodeId != null && (
        <CreateAdditionalWorkExperienceNodeOption
          onboardingNodeId={currentOnboardingNodeId}
          type="original-contribution"
          name="original-contribution"
          description="If you have any other original contributions that you'd like to add, please add them here."
        />
      )}
    </OnboardingForm>
  );
};
