import { OnboardingForm } from "@/components/form";
import { LetterSigner, LetterSigners } from "@/components/letterSigners";
import { useOnboardingNodeData } from "@/lib/hooks";
import { useOnboarding } from "@/providers/onboardingProvider";

export const SupervisorLetter = () => {
  const { currentOnboardingNodeId, nodeStatus } = useOnboarding();
  const { data, doSetData } = useOnboardingNodeData(
    currentOnboardingNodeId ?? -1
  );

  return (
    <OnboardingForm heading="Support Letters">
      <span className="text-grey-300 text-[12px] mt-4 pr-12">
        Like all of the supporting documentation in your application, the USCIS
        will want evidence to prove our claims.
        <span className="text-grey-200">
          Supervisor letters are brief letters to verify roles you've held as
          part of your professional accomplishments included in your
          application.
        </span>{" "}
      </span>

      <LetterSigners
        value={(data.signers?.value ?? []) as LetterSigner[]}
        onChange={(signers) => doSetData("signers", signers)}
        disabled={
          nodeStatus === "submitted_for_review" || nodeStatus === "approved"
        }
      />
    </OnboardingForm>
  );
};
