import { FormCard, FormTitleInfoIcon } from "@/components/form";
import { LockIcon } from "@/components/icons/sidebar";
import { WarningIcon } from "@/components/icons/warning";
import { ONBOARDING_STEP_MAP } from "@/components/onboarding/steps/stepmap";
import { useSidebarStore } from "@/components/sidebar";
import { SidebarFrame } from "@/components/sidebarFrame";
import { cn } from "@/lib/cn";
import { useWorkspaces } from "@/lib/hooks";
import { getOnboarding, getOnboardingNode } from "@/lib/queries";
import { OnboardingDataProvider } from "@/providers/onboardingDataProvider";
import {
  OnboardingNodeGroups,
  OnboardingRouteProvider,
  useOnboarding,
} from "@/providers/onboardingProvider";
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  CrossCircledIcon,
} from "@radix-ui/react-icons";
import { Heading, Spinner } from "@radix-ui/themes";
import {
  createFileRoute,
  useNavigate,
  useRouter,
} from "@tanstack/react-router";
import { Fragment, useEffect } from "react";
import { useQuery } from "urql";

const SubmittedBanner = () => {
  const { nodeStatus } = useOnboarding();

  if (nodeStatus !== "submitted_for_review") return null;
  const sidebarOpen = useSidebarStore((state) => state.open);

  return (
    <div
      className={cn(
        "absolute top-0 left-0 w-full z-10 flex flex-col flex items-center",
        sidebarOpen && "left-30",
        !sidebarOpen && "left-25"
      )}
    >
      <div className="max-w-[800px] flex flex-row items-center bg-grey-100 gap-2 rounded-lg py-3 px-4 mt-2 text-grey-500 text-xs">
        <LockIcon />
        This section has been submitted, and is not editable.
      </div>
    </div>
  );
};

const FloatingRejectionBar = () => {
  const {
    currentRejectedFieldIndex,
    setCurrentRejectedFieldIndex,
    rejectedFields,
  } = useOnboarding();

  const sidebarOpen = useSidebarStore((state) => state.open);

  if (rejectedFields.length === 0) return null;

  return (
    <div
      className={cn(
        "absolute bottom-0 left-0 w-full z-40 flex flex-col items-center mb-1",
        sidebarOpen && "left-30",
        !sidebarOpen && "left-25"
      )}
    >
      <div className="max-w-[600px] w-[50%] flex flex-row items-center bg-grey-100 gap-2 rounded-lg py-3 px-4">
        <WarningIcon />
        <span className="text-grey-800 text-sm">
          Fields in this step have been rejected
        </span>

        <div className="flex flex-row gap-2 ml-auto">
          <button
            className="p-1 bg-grey-300 rounded-sm text-grey-800 disabled:opacity-50"
            disabled={
              currentRejectedFieldIndex == null ||
              currentRejectedFieldIndex === 0
            }
            onClick={() =>
              setCurrentRejectedFieldIndex(
                currentRejectedFieldIndex == null
                  ? 0
                  : currentRejectedFieldIndex - 1
              )
            }
          >
            <ArrowLeftIcon />
          </button>
          <span className="text-grey-400 text-sm flex flex-row items-center gap-1">
            <span className="text-grey-800 text-sm">
              {currentRejectedFieldIndex != null
                ? currentRejectedFieldIndex + 1
                : 0}
            </span>
            of {rejectedFields.length}
          </span>
          <button
            className="p-1 bg-grey-300 rounded-sm text-grey-800 disabled:opacity-50"
            disabled={currentRejectedFieldIndex === rejectedFields.length - 1}
            onClick={() =>
              setCurrentRejectedFieldIndex(
                currentRejectedFieldIndex == null
                  ? 0
                  : currentRejectedFieldIndex + 1
              )
            }
          >
            <ArrowRightIcon />
          </button>
        </div>
        <button className="ml-2 text-grey-300">
          <CrossCircledIcon className="w-4 h-4" />
        </button>
      </div>
    </div>
  );
};

const ConditionalRender = (props: {
  group: OnboardingNodeGroups;
  onboardingId: number;
  nodeId: number;
  nodeType: string;
}) => {
  const { group, nodeType, nodeId, onboardingId } = props;

  return (
    <>
      <OnboardingDataProvider onboardingId={onboardingId}>
        <OnboardingRouteProvider
          onboardingId={onboardingId}
          currentGroup={group}
          currentNodeId={nodeId}
        >
          {group === "gate" &&
            ONBOARDING_STEP_MAP[nodeType] != null &&
            ONBOARDING_STEP_MAP[nodeType]}

          {group !== "gate" && ONBOARDING_STEP_MAP[nodeType] != null && (
            <SidebarFrame>
              <div className="w-full flex flex-col h-full relative">
                {ONBOARDING_STEP_MAP[nodeType]}

                <FloatingRejectionBar />
                <SubmittedBanner />
              </div>
            </SidebarFrame>
          )}
        </OnboardingRouteProvider>
      </OnboardingDataProvider>
    </>
  );
};

const Placeholder = (props: {
  onboardingId: number;
  currentOnboardingGroup:
    | "gate"
    | "about_you"
    | "work_experience"
    | "support_letters"
    | "employer_documents";
}) => {
  const { onboardingId, currentOnboardingGroup } = props;

  const header = {
    gate: "Onboarding",
    about_you: "Your personal details and visa background",
    work_experience: "Your work experience",
    support_letters: "Support Letters",
    employer_documents: "Employer Documents",
  };

  return (
    <OnboardingDataProvider onboardingId={onboardingId}>
      <SidebarFrame>
        <div
          className={cn(
            "w-full h-full rounded-xl bg-cover bg-center flex flex-col items-center py-16 overflow-hidden justify-center",
            currentOnboardingGroup == "about_you" &&
              "bg-[url(/backgrounds/about-you.webp)] bg-center",
            currentOnboardingGroup == "work_experience" &&
              "bg-[url(/backgrounds/work-experience.webp)] bg-center",
            currentOnboardingGroup == "support_letters" &&
              "bg-[url(/backgrounds/support-letters.webp)] bg-bottom"
          )}
        >
          <div className="flex flex-col gap-5 w-[55%] h-full">
            <div className="h-[157px] w-full flex flex-col pt-6 pb-1 pl-12 pr-6 gap-6 bg-[#F7F7F7] bg-opacity-[64%] rounded-xl flex-shrink-0 sm:h-fit backdrop-blur-md">
              <div className="flex items-center w-full gap-2">
                <Heading>{header[currentOnboardingGroup]}</Heading>
                <div className="ml-auto">
                  <FormTitleInfoIcon />
                </div>
              </div>

              <div className="mt-auto h-[64px] pb-[20px]">
                {/* <div className="flex flex-row gap-3 items-center w-full">
                  <div className="flex flex-col gap-2">
                    <div className="rounded-full w-4 h-4 animate-pulse bg-grey-700" />
                    <div className="w-48 h-4 animate-pulse bg-grey-700 rounded-sm" />
                  </div>
                  <div className="flex flex-col gap-2">
                    <div className="rounded-full w-4 h-4 animate-pulse bg-grey-700" />
                    <div className="w-48 h-4 animate-pulse bg-grey-700 rounded-sm" />
                  </div>
                  <div className="flex flex-col gap-2">
                    <div className="rounded-full w-4 h-4 animate-pulse bg-grey-700" />
                    <div className="w-48 h-4 animate-pulse bg-grey-700 rounded-sm" />
                  </div>
                </div> */}
              </div>
            </div>

            <FormCard
              className={
                "h-full flex flex-col items-center justify-center p-12"
              }
            >
              <div className="flex flex-col gap-6 w-full">
                {/* <div className="w-1/3 h-6 animate-pulse bg-grey-700 rounded-sm" />
                <div className="w-full h-12 animate-pulse bg-grey-700 rounded-sm" />
                <div className="w-2/3 h-6 animate-pulse bg-grey-700 rounded-sm" />
                <div className="w-full h-8 animate-pulse bg-grey-700 rounded-sm" />
                <div className="w-1/2 h-6 animate-pulse bg-grey-700 rounded-sm" /> */}
              </div>
            </FormCard>
          </div>
        </div>
      </SidebarFrame>
    </OnboardingDataProvider>
  );
};

const RenderOnboardingStep = (props: {
  currentOnboardingGroup: OnboardingNodeGroups;
  currentOnboardingNodeId: number;
  onboardingId: number;
}) => {
  const { currentOnboardingNodeId, currentOnboardingGroup, onboardingId } =
    props;

  const [{ data, fetching, error }] = useQuery({
    query: getOnboardingNode,
    variables: { id: currentOnboardingNodeId },
    requestPolicy: "cache-and-network",
  });

  useEffect(() => {
    if (error != null) {
      console.error(error);
    }
  }, [error]);

  return (
    <>
      {fetching && data == null && currentOnboardingGroup !== "gate" && (
        <Placeholder
          onboardingId={onboardingId}
          currentOnboardingGroup={currentOnboardingGroup}
        />
      )}
      {fetching && data == null && currentOnboardingGroup === "gate" && (
        <Spinner />
      )}
      {error != null && (
        <div className="w-full h-full flex flex-col items-center justify-center">
          Error loading onboarding data for node {currentOnboardingNodeId}.
          Please contact support@lighthousehq.com for assistance.
        </div>
      )}
      {data != null && (
        <Fragment key={`onboarding-step-${currentOnboardingNodeId}`}>
          <ConditionalRender
            group={currentOnboardingGroup}
            nodeType={data.onboardingNode.type}
            nodeId={currentOnboardingNodeId}
            onboardingId={onboardingId}
          />
        </Fragment>
      )}
    </>
  );
};

const GroupOnboardingWrapper = () => {
  const { groupId } = Route.useParams();
  const { nodeId } = Route.useSearch();
  const nav = useNavigate();
  const router = useRouter();

  const { selectedWorkspace } = useWorkspaces();

  const onboardingId = selectedWorkspace?.selectedOnboardingId;

  const [{ data, fetching }] = useQuery({
    query: getOnboarding,
    variables: { id: onboardingId ?? -1 },
    pause: onboardingId == null || !selectedWorkspace?.id.startsWith("user"),
  });

  useEffect(() => {
    if (data == null) return;

    for (const group of data.onboarding.groups) {
      for (const node of group.nodes) {
        try {
          router.preloadRoute({
            to: "/group/$groupId",
            params: { groupId: group.id },
            search: { nodeId: node.id },
          });
        } catch (e) {
          console.error(e);
        }
      }
    }
  }, [data, router]);

  if (selectedWorkspace?.id.startsWith("company")) {
    return;
  }

  const groupInOnboarding = data?.onboarding.groups.find(
    (x) => x.id === groupId
  );

  if (groupInOnboarding == null) {
    if (data == null && fetching) {
      return <Spinner />;
    }

    if (!fetching) {
      nav({ to: "/home" });
    }
    return;
  }

  const currentOnboardingNodeId = groupInOnboarding.nodes.find(
    (x) => x.id === Number(nodeId)
  )?.id;

  if (currentOnboardingNodeId == null) {
    nav({
      to: "/group/$groupId",
      params: { groupId },
      search: { nodeId: groupInOnboarding.nodes[0]?.id },
    });
    return;
  }

  return (
    <div className="w-full h-full">
      {data == null && groupId != "gate" && (
        <Placeholder
          onboardingId={onboardingId ?? -1}
          currentOnboardingGroup={groupId as OnboardingNodeGroups}
        />
      )}
      {data == null && groupId === "gate" && <Spinner />}
      {onboardingId != null && (
        <RenderOnboardingStep
          currentOnboardingGroup={groupId as OnboardingNodeGroups}
          currentOnboardingNodeId={currentOnboardingNodeId}
          onboardingId={onboardingId}
        />
      )}
    </div>
  );
};

export const Route = createFileRoute("/_portal/group/$groupId")({
  validateSearch: (search: Record<string, unknown>): { nodeId?: number } => {
    return {
      nodeId: search.nodeId == null ? undefined : Number(search.nodeId),
    };
  },
  beforeLoad: async ({ context, search }) => {
    if (search.nodeId != null) {
      context.client.query(getOnboardingNode, {
        id: search.nodeId,
      });
    }
  },
  component: () => <GroupOnboardingWrapper />,
});
