import { Modal } from "./modal";
import {
  approvedCompiledCaseAction,
  getOnboarding,
  updateCompiledCaseAction,
} from "@/lib/queries";
import { ResultOf } from "gql.tada";
import { Button } from "./button";
import { ArrowUpIcon, CheckCircledIcon } from "@radix-ui/react-icons";

import { useRef, useState } from "react";

import { LoadingOverlay } from "./loadingOverlay";
import { Editor, EditorContent, Extensions, useEditor } from "@tiptap/react";
import Link from "@tiptap/extension-link";
import Underline from "@tiptap/extension-underline";
import StarterKit from "@tiptap/starter-kit";
import Placeholder from "@tiptap/extension-placeholder";
import { FiEdit, FiTrash } from "react-icons/fi";
import { Tooltip, TooltipContent, TooltipTrigger } from "./tooltip";
import { useMutation } from "urql";
import { InReviewIcon } from "./icons/inReview";
import { useRerenderOnEditorChange } from "@lighthouse/editor";
import { useResizeObserver } from "@/lib/hooks";
import { Document, Page } from "react-pdf";
import { Spinner } from "@radix-ui/themes";
import { FixedSizeList as List } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";

const extensions: Extensions = [
  StarterKit,
  Underline,
  Link,
  Placeholder.configure({
    emptyEditorClass: "is-editor-empty",
    placeholder: "Add your comments...",
  }),
];

const ApproveButton = (props: {
  compiledCaseAction: NonNullable<
    ResultOf<typeof getOnboarding>["onboarding"]["publishedCase"][0]
  >;
}) => {
  const { compiledCaseAction } = props;

  const [loading, setLoading] = useState(false);

  const approveMutation = useMutation(approvedCompiledCaseAction)[1];

  const approve = async () => {
    if (loading) return;

    setLoading(true);

    await approveMutation({
      input: {
        id: compiledCaseAction.id,
      },
    });

    setLoading(false);
  };

  return (
    <>
      <LoadingOverlay isLoading={loading} />

      {compiledCaseAction.status === "approved" && (
        <Button
          variant="primary"
          disabled
          className="disabled:bg-grey-100 disabled:text-grey-500 disabled:shadow-primary-button"
        >
          <InReviewIcon />
          Application Approved
        </Button>
      )}

      {compiledCaseAction.status !== "approved" && (
        <Button
          variant="primary"
          onClick={approve}
          className="disabled:bg-grey-100 disabled:text-grey-400 disabled:shadow-primary-button"
        >
          Approve Petition
        </Button>
      )}
    </>
  );
};

const SubmitCommentButton = (props: {
  editor: Editor;
  compiledCaseAction: NonNullable<
    ResultOf<typeof getOnboarding>["onboarding"]["publishedCase"][0]
  >;
  onSubmit: () => void;
}) => {
  const { editor, compiledCaseAction, onSubmit } = props;

  useRerenderOnEditorChange(editor);

  const [loading, setLoading] = useState(false);
  const submitCommentMutation = useMutation(updateCompiledCaseAction)[1];

  const submitComment = async () => {
    const content = editor.getHTML();

    if (content === "") return;

    setLoading(true);

    await submitCommentMutation({
      input: {
        id: compiledCaseAction.id,
        comments: content,
      },
    });

    setLoading(false);
    onSubmit();
  };

  return (
    <Button
      variant="primary"
      onClick={submitComment}
      loading={loading}
      disabled={editor.isEmpty}
      className="text-xs w-8 h-8 flex items-center justify-center rounded-[8px] hover:cursor-pointer disabled:cursor-not-allowed shadow-button disabled:bg-grey-700 disabled:text-grey-500 transition-all duration-300 bg-grey-100 text-grey-800 hover:bg-grey-200 shadow-primary-button mt-2 disabled:shadow-none"
    >
      <ArrowUpIcon />
    </Button>
  );
};

const CommentRenderer = (props: {
  compiledCaseAction: NonNullable<
    ResultOf<typeof getOnboarding>["onboarding"]["publishedCase"][0]
  >;
}) => {
  const { compiledCaseAction } = props;

  const [editing, setEditing] = useState(false);

  const editor = useEditor(
    {
      editorProps: {
        attributes: {
          class: "prose prose-sm",
        },
      },
      autofocus: false,
      content: compiledCaseAction.comments,
      editable:
        compiledCaseAction.type === "review" &&
        compiledCaseAction.status === "pending",
      extensions: extensions,
    },
    [extensions, compiledCaseAction]
  );

  const enableEditing = () => {
    setEditing(true);
    editor?.setEditable(true);
    editor?.commands.focus();
  };

  const discardEdits = () => {
    setEditing(false);
    editor?.setEditable(false);
    editor?.commands.setContent(compiledCaseAction.comments);
  };

  if (compiledCaseAction.type === "view") return;

  return (
    <div className="w-full min-w-[300px] max-h-full flex flex-col bg-grey-800 rounded-lg transition-all group/container ring-2 ring-grey-600 ring-inset">
      <div className="flex flex-col gap-1 p-4">
        <span className="text-xs text-grey-200 font-medium">
          {compiledCaseAction.status === "pending"
            ? "Request Changes"
            : compiledCaseAction.status === "changes_requested"
              ? "Changes Requested"
              : "Case Approved"}
        </span>

        <span className="text-xs text-grey-400">
          {compiledCaseAction.status === "changes_requested" &&
            "We have received your comments below on this form. We will review them and get back to you with changes."}

          {compiledCaseAction.status === "pending" &&
            "If you have any comments on this application, please enter them below and submit. We will review them and get back to you with changes."}

          {compiledCaseAction.status === "approved" &&
            "This visa application has been approved."}
        </span>

        {compiledCaseAction.status === "changes_requested" && !editing && (
          <button
            onClick={enableEditing}
            className="w-fit text-xs text-grey-200 flex flex-row items-center gap-1.5 mt-1"
          >
            <FiEdit />
            <span>Edit submission</span>
          </button>
        )}
      </div>

      <div className="w-full h-[1px] bg-grey-600"></div>

      <div className="flex flex-col p-4 py-3 overflow-hidden">
        <div className="w-full max-h-full overflow-y-scroll">
          <EditorContent
            editor={editor}
            className="w-full flex-grow-0 overflow-hidden"
          />
        </div>

        <div className="flex flex-row justify-end gap-2 items-end">
          {editing && (
            <Tooltip>
              <TooltipTrigger asChild>
                <button
                  onClick={discardEdits}
                  className="text-xs w-8 h-8 flex items-center justify-center rounded-[8px] shadow-button bg-grey-800 text-grey-100 hover:bg-[#E9E9E9] shadow-primary-button"
                >
                  <FiTrash />
                </button>
              </TooltipTrigger>
              <TooltipContent side="top" sideOffset={5} className="p-2">
                Discard edit
              </TooltipContent>
            </Tooltip>
          )}

          {editor != null &&
            (compiledCaseAction.status === "pending" || editing) && (
              <SubmitCommentButton
                editor={editor}
                compiledCaseAction={compiledCaseAction}
                onSubmit={() => {
                  setEditing(false);
                }}
              />
            )}
        </div>
      </div>
    </div>
  );
};

const PageRenderer = (props: {
  index: number;
  style: any;
  data: { width: number; presignedUrl: string };
}) => {
  const { index, style, data } = props;
  return (
    <div style={style} className="flex justify-center">
      <Page
        pageIndex={index}
        width={0.984 * data.width}
        loading={null}
        renderAnnotationLayer={false}
        renderTextLayer={false}
        renderForms={false}
        className="rounded-md border border-grey-600 p-1 shadow-border my-2"
      />
    </div>
  );
};

const FileRenderer = (props: { presignedUrl: string }) => {
  const { presignedUrl } = props;
  const [numPages, setNumPages] = useState<number>();
  const documentRef = useRef<HTMLDivElement>(null);
  const { width } = useResizeObserver(documentRef);

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  };

  return (
    <div ref={documentRef} className="w-full h-full">
      <Document
        loading={<Spinner className="w-3 h-3 animate-spin text-grey-400" />}
        className="w-full h-full"
        file={presignedUrl}
        onLoadSuccess={onDocumentLoadSuccess}
        key={presignedUrl}
      >
        {numPages && width && (
          <AutoSizer>
            {({ height }) => (
              <List
                height={height}
                itemCount={numPages}
                itemSize={Math.floor(width * 1.4)} // Approximate height based on typical PDF aspect ratio
                width={width}
                itemData={{ width, presignedUrl }}
                className="pb-4"
              >
                {PageRenderer}
              </List>
            )}
          </AutoSizer>
        )}
      </Document>
    </div>
  );
};

const PetitionViewer = (props: {
  publishedCase: NonNullable<
    ResultOf<typeof getOnboarding>["onboarding"]["publishedCase"][0]
  >;
}) => {
  const { publishedCase } = props;

  return (
    <div className="flex flex-col h-full bg-grey-700 px-8 pt-6 gap-5">
      <div className="flex flex-row items-center justify-between flex-shrink-0">
        <div className="flex flex-row items-center gap-4">
          <span className="text-grey-200 font-medium text-sm">
            {publishedCase.name}
          </span>

          {publishedCase.type === "review" &&
            publishedCase.status === "pending" && (
              <div className="px-3 py-[3px] text-[#558f40] text-[11px] font-medium bg-[#81CA67] bg-opacity-20 rounded-full ring-1 ring-[#81CA67] ring-opacity-25 shadow-positive-2">
                Ready to review
              </div>
            )}
          {publishedCase.type === "review" &&
            publishedCase.status === "changes_requested" && (
              <div className="px-3 py-[3px] text-[#a48645] text-[11px] font-medium bg-[#caab67] bg-opacity-20 rounded-full ring-1 ring-[#caab67] ring-opacity-25 shadow-sm">
                Changes Requested
              </div>
            )}
          {publishedCase.type === "review" &&
            publishedCase.status === "approved" && (
              <div className="flex flex-row gap-1 items-center px-3 py-[3px] text-[#488433] text-[11px] font-medium bg-[#5da045] bg-opacity-20 rounded-full ring-1 ring-[#5da045] ring-opacity-25 shadow-positive-2">
                <CheckCircledIcon /> <span>Approved</span>
              </div>
            )}
        </div>
        {publishedCase.type === "review" && (
          <ApproveButton compiledCaseAction={publishedCase} />
        )}
      </div>

      <div className="flex flex-row items-start gap-4 flex-grow overflow-x-auto justify-start lg:justify-center">
        <div className="flex-col h-full flex-1 pb-6 max-w-[250px] min-w-[200px] flex-shrink-0 hidden lg:flex gap-4">
          {publishedCase.changelogHtml != null && (
            <div className="flex flex-col w-full">
              <h2 className="text-xs text-grey-400">Lighthouse Notes</h2>

              <div className="overflow-y-auto h-full flex flex-col pr-3 py-3">
                <div
                  dangerouslySetInnerHTML={{
                    __html: publishedCase.changelogHtml,
                  }}
                  className="text-xs text-grey-200 prose prose-xs prose-ul:my-[0.5rem] prose-ol:my-[0.5rem] prose-li:my-[0.5rem]"
                />
              </div>
            </div>
          )}

          {/* <div className="text-grey-300 text-xs bg-grey-600 p-3 rounded-lg border border-grey-500 border-opacity-50 font-light mt-auto">
            {compiledCaseAction.status === "approved"
              ? "This visa application has been approved."
              : "Once your application is approved, we can move forward with submission. Leave a comment if you have any suggestions for changes."}
          </div> */}
        </div>

        <div className="flex flex-col h-full min-w-[600px] max-w-[800px] flex-[2] gap-4">
          {/* {compiledCaseAction.type === "review" &&
            compiledCaseAction.status === "approved" && (
              <div className="flex flex-col w-full bg-grey-600 p-3 rounded-lg border border-grey-500 border-opacity-50 gap-4">
                <span className="text-sm text-grey-300 font-light">
                  You have approved this visa application.
                </span>
              </div>
            )} */}

          <FileRenderer presignedUrl={publishedCase.file.presignedUrl} />
        </div>

        <div className="flex flex-col h-full flex-1 items-start pb-6 max-w-[400px] min-w-[300px] flex-shrink-0">
          {publishedCase.status !== "approved" && (
            <CommentRenderer compiledCaseAction={publishedCase} />
          )}
        </div>
      </div>
    </div>
  );
};

export const PetitionReviewModal = (props: {
  open: boolean;
  setOpen: (value: boolean) => void;
  publishedCase: NonNullable<
    ResultOf<typeof getOnboarding>["onboarding"]["publishedCase"][0]
  >;
}) => {
  const { open, setOpen, publishedCase: petition } = props;

  return (
    <div key={`petition-review-${petition.id}`}>
      <Modal
        open={open}
        onOpenChange={setOpen}
        title={
          petition.type === "review"
            ? "Review Visa Application"
            : "View Visa Application"
        }
        borderUnderTitle
        contentClassName="w-[90vw] h-[90vh] max-h-[90vw] max-w-[90vw]"
        titleClassName="pt-4 pb-4"
        childrenClassName="p-0"
      >
        <PetitionViewer publishedCase={petition} />
      </Modal>
    </div>
  );
};
