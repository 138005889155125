import { useEffect, useState } from "react";
import { Mark } from "./mark";
import { motion, AnimatePresence } from "framer-motion";

export const ExternalLandingPage = (props: { hide: () => void }) => {
  const { hide } = props;

  const [isVisible, setIsVisible] = useState(true);

  const doHide = () => {
    hide(); // Call hide immediately to show the modal
    setIsVisible(false); // Start the fade out animation
  };

  useEffect(() => {
    const down = (e: KeyboardEvent) => {
      if (e.key === "Enter") {
        e.preventDefault();
        doHide();
      }
    };

    document.addEventListener("keydown", down);

    return () => {
      document.removeEventListener("keydown", down);
    };
  }, []);

  return (
    <AnimatePresence mode="wait">
      {isVisible && (
        <motion.div
          className="w-screen h-screen flex items-center justify-center fixed top-0 left-0 z-50"
          initial={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 1, ease: "easeOut" }}
          style={{
            background: "url('/backgrounds/external.webp')",
            backgroundBlendMode: "color-burn",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundColor: "#E7E7E8",
          }}
        >
          <motion.div
            className="absolute top-7 left-10"
            initial={{ opacity: 0, y: 0, filter: "blur(5px)" }}
            animate={{ opacity: 1, y: 0, filter: "blur(0px)" }}
            transition={{ duration: 0.5, delay: 0.1 }}
          >
            <Mark />
          </motion.div>

          <motion.div
            className="flex flex-col w-[500px] max-w-[80%] items-start gap-6"
            initial={{ opacity: 0, y: 5, filter: "blur(5px)" }}
            animate={{ opacity: 1, y: 0, filter: "blur(0px)" }}
            exit={{ opacity: 0, y: 10, filter: "blur(10px)" }}
            transition={{ duration: 0.5, delay: 0.1 }}
          >
            <h1 className="text-3xl text-grey-200 font-semibold font-serif">
              Welcome to Lighthouse
            </h1>

            <p className="text-grey-200 font-normal">
              We are the fastest immigration solution for technical talent and
              fast growing teams. Our mission is to democratize access to high
              skilled immigration by making visa preparation fast and
              accessible.
            </p>

            <p className="text-grey-200 font-semibold">
              Thank you for supporting exceptional talent.
            </p>

            <motion.button
              onClick={doHide}
              className="text-xs text-grey-300 font-normal mt-5"
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
            >
              Press{" "}
              <span className="text-grey-300 bg-grey-500 py-0.5 px-1 rounded">
                Enter
              </span>{" "}
              to continue
            </motion.button>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};
