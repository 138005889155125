import { getOnboarding } from "@/lib/queries";
import { useQuery } from "urql";
import { HighlightedText, LabelText } from "./typography";
import { cn } from "@/lib/cn";
import { Link } from "@tanstack/react-router";

import { graphql, ResultOf } from "@/lib/graphql";
import { SupportLetterIcon } from "./icons/sidebar";
import { Button } from "./button";
import { LetterModal } from "./letterModal";
import { useState } from "react";

type LetterActionType = ReturnType<typeof graphql.scalar<"LetterActionType">>;
type LetterActionRecipient = ReturnType<
  typeof graphql.scalar<"LetterActionRecipient">
>;

const StatusBadge = (props: {
  status: "sent" | "changes_requested" | "approved";
  type: "reviewApprove" | "sign";
}) => {
  const statusClassNames: Record<
    "sent" | "changes_requested" | "approved",
    string
  > = {
    approved:
      "bg-grey-300 bg-opacity-[8%] border border-grey-300 border-opacity-[8%] text-grey-300",
    sent: "bg-positive bg-opacity-[8%] border border-positive text-positive border-opacity-[8%]",
    changes_requested:
      "bg-[#D2449114] bg-opacity-[8%] border border-[#D2449114] border-opacity-[8%] text-[#D24491]",
  };

  const statusNames = {
    sent: props.type === "reviewApprove" ? "Ready to review" : "Ready to sign",
    changes_requested: "Changes Requested",
    approved: "Approved",
  };

  return (
    <div
      className={cn(
        "flex flex-row items-center gap-1 px-2 py-1 text-xs font-semibold rounded-full text-nowrap",
        statusClassNames[props.status]
      )}
    >
      {statusNames[props.status]}
    </div>
  );
};

export const LinkToLetterAction = (props: {
  id: number;
  type: LetterActionType;
  children: React.ReactNode;
}) => {
  return (
    <Link
      to={
        props.type === "reviewApprove"
          ? "/letter/review/$letterActionId"
          : "/letter/sign/$letterActionId"
      }
      params={{ letterActionId: props.id.toString() }}
    >
      {props.children}
    </Link>
  );
};

export const ActionRow = (props: {
  id: number;
  letterName: string;
  status: string;
  type: LetterActionType;
  compact: boolean;
}) => {
  const { id, letterName, status, type } = props;

  const [modalOpen, setModalOpen] = useState(false);

  return (
    <div
      className={cn(
        "w-full grid grid-cols-4 px-3 py-2.5 items-center border-b border-b-grey-600"
      )}
      id={`letter-action-${id}`}
    >
      <LetterModal
        open={modalOpen}
        setOpen={setModalOpen}
        letterActionId={id}
      />
      <div className={cn("flex flex-row items-center gap-2 w-full col-span-2")}>
        <SupportLetterIcon />
        <span className="text-xs text-neutral-600 whitespace-nowrap text-ellipsis overflow-hidden text-nowrap">
          {letterName}
        </span>
      </div>

      <div className="flex w-full">
        <StatusBadge
          status={status as "sent" | "changes_requested" | "approved"}
          type={type}
        />
      </div>

      <div className="flex justify-end">
        <Button
          variant={
            status === "sent"
              ? "primary"
              : status === "changes_requested"
                ? "secondary"
                : "secondary"
          }
          className="w-[78px] text-nowrap"
          onClick={() => setModalOpen(true)}
        >
          {status === "sent" && type === "reviewApprove"
            ? "Review"
            : status === "sent"
              ? "Sign"
              : status === "changes_requested"
                ? "Changes Requested"
                : "View"}
        </Button>
      </div>
    </div>
  );
};

const letterActionStatusOrder = {
  draft: -1,
  pending: -1,
  sent: 0,
  changes_requested: 1,
  approved: 2,
};

const getLetterActionsToShow = (
  data: ResultOf<typeof getOnboarding> | undefined,
  recipient: LetterActionRecipient
) => {
  const letterActions = data?.onboarding.letterActions ?? [];

  const filteredLetterActions = letterActions.filter(
    (action) => action.recipientType === recipient
  );

  // Group letter actions by letterId
  const groupedByLetterId = filteredLetterActions.reduce(
    (acc, action) => {
      if (!acc[action.letterId]) {
        acc[action.letterId] = [];
      }
      acc[action.letterId].push(action);
      return acc;
    },
    {} as Record<number, typeof letterActions>
  );

  // For each letter, get the most relevant action
  return Object.values(groupedByLetterId).map((actions) => {
    // Sort actions by status order and take the first one
    return actions.sort((a, b) => {
      // If one action is approved and the other isn't, prioritize the non-approved action
      if (a.status === "approved" && b.status !== "approved") return 1;
      if (a.status !== "approved" && b.status === "approved") return -1;

      // If statuses are different, show lower status first
      const statusComparison =
        letterActionStatusOrder[a.status] - letterActionStatusOrder[b.status];
      if (statusComparison !== 0) return statusComparison;

      // If statuses are the same, show the higher ID (more recent) first
      return b.id - a.id;
    })[0];
  });
};

export const LetterActions = (props: {
  onboardingId: number;
  recipient: LetterActionRecipient;
  compact?: boolean;
}) => {
  const { onboardingId, compact = false, recipient } = props;

  const [{ data }] = useQuery({
    query: getOnboarding,
    variables: {
      id: onboardingId,
    },
    requestPolicy: "network-only",
  });

  const sortedLetterActions = getLetterActionsToShow(data, recipient);

  return (
    <div className="flex flex-col">
      {recipient === "beneficiary" && (
        <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-1">
            <LabelText className="text-sm text-grey-100" as="span">
              Support Letters
            </LabelText>

            <LabelText className={cn("text-sm text-grey-300 w-1/2")} as="span">
              Your application will contain support letter drafts for your
              review. Review, approve, and then send them off for signature by
              selecting each letter below.{" "}
              <HighlightedText className="px-1 py-0.5 rounded-sm">
                Learn more
              </HighlightedText>
            </LabelText>
          </div>

          {sortedLetterActions.length === 0 && (
            <div className="flex flex-col px-1 justify-center items-center">
              <img
                className="w-[167px] h-[109px]"
                src="/icons/support-letters.png"
              />
              <div className="text-sm text-grey-300 mt-2 text-center w-[500px]">
                <span className="text-grey-200">
                  Your draft letters will appear here{" "}
                </span>
                once you fill in the necessary information in the Support
                letters step, we'll prepare it for your approval within 3
                business days.
              </div>
            </div>
          )}

          {sortedLetterActions.length > 0 && (
            <div className={cn("flex flex-col gap-3 mb-2")}>
              {sortedLetterActions.map((action) => (
                <ActionRow
                  key={action.id}
                  id={action.id}
                  letterName={action.letterName}
                  status={action.status}
                  type={action.type}
                  compact={compact}
                />
              ))}
            </div>
          )}
        </div>
      )}

      {recipient === "company" && sortedLetterActions.length > 0 && (
        <div className="flex flex-col gap-2 px-2 mb-2">
          <div className="flex flex-col gap-1">
            <LabelText className="text-sm text-grey-300" as="span">
              Support Letters
            </LabelText>

            <LabelText className={cn("text-xs text-grey-300 w-full")} as="span">
              We need your signature on some letters to complete this
              application.
            </LabelText>
          </div>

          <div className={cn("flex flex-col gap-3 mb-2")}>
            {sortedLetterActions.map((action) => (
              <ActionRow
                key={action.id}
                id={action.id}
                letterName={action.letterName}
                status={action.status}
                type={action.type}
                compact={compact}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
