import { CreateAdditionalWorkExperienceNodeOption } from "@/components/createAdditionalWorkExperienceNodeOption";
import { Field, OnboardingForm, SubkeyUploadArea } from "@/components/form";
import { DateInput, Input, TextArea } from "@/components/inputs";
import { HighlightedText } from "@/components/typography";
import { useOnboardingNodeData } from "@/lib/hooks";
import { useOnboarding } from "@/providers/onboardingProvider";

export const Membership = () => {
  const { currentOnboardingNodeId } = useOnboarding();
  const { data, doSetData } = useOnboardingNodeData(
    currentOnboardingNodeId ?? -1
  );

  return (
    <OnboardingForm heading="Your work experience">
      <p className="text-[12px] text-grey-300 mr-12 mt-2">
        This is evidence that{" "}
        <span className="text-grey-200">
          you've been selected into an organization for outstanding
          achievements.
        </span>{" "}
        Our team will also draft a letter for the organization to sign as well.{" "}
        <HighlightedText>Learn more</HighlightedText>
      </p>
      <Field id="organizationTitle" label="Organization title">
        <Input
          type="text"
          placeholder="e.g. YCombinator"
          value={data.organizationTitle?.value as string}
          onValueChange={(val) => doSetData("organizationTitle", val)}
        />
      </Field>

      <Field
        id="selectedDate"
        label={`When were you selected to be a member of ${
          data.organizationTitle?.value == null
            ? "this membership"
            : `${data.organizationTitle?.value}`
        }?`}
      >
        <DateInput
          value={
            data.selectedDate?.value != null
              ? new Date(data.selectedDate.value)
              : undefined
          }
          onValueChange={(val) => doSetData("selectedDate", val?.toISOString())}
        />
      </Field>

      <Field
        id="supportingDocuments"
        label={`Upload confirmation you're a member of ${
          data.organizationTitle?.value == null
            ? "this membership"
            : `${data.organizationTitle?.value}`
        }`}
      >
        <SubkeyUploadArea
          onboardingNodeId={currentOnboardingNodeId ?? -1}
          subKey="supportingDocuments"
        />
      </Field>

      <Field
        id="additionalComments"
        label="Additional comments"
        description="If you have any additional comments, that you think will be useful for us working on this criteria, please add them here."
      >
        <TextArea
          value={data.additionalComments?.value as string}
          onChange={(val) => doSetData("additionalComments", val)}
          className="w-full"
          charLimit={500}
        />
      </Field>

      {currentOnboardingNodeId != null && (
        <CreateAdditionalWorkExperienceNodeOption
          onboardingNodeId={currentOnboardingNodeId}
          type="membership"
          name="membership"
          description="If you have any other memberships that you'd like to add, please add them here."
        />
      )}
    </OnboardingForm>
  );
};
