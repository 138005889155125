import { useAuth } from "@clerk/clerk-react";
import { graphql } from "./graphql";
import { useClient } from "urql";

export const getUserContext = graphql(`
  query context($userContextInput: UserContextInput!) {
    context(userContextInput: $userContextInput) {
      __typename
      id

      contextUserId
      loggedInCustomerUserId

      userEntity {
        __typename
        id
        legalName
        role
        phoneNumber
        firstName
        lastName
      }
    }
  }
`);

export const getOnboardingContext = graphql(`
  query getOnboardingContext($contextInput: UserContextInput!) {
    context(userContextInput: $contextInput) {
      __typename
      id

      contextUserId
      loggedInCustomerUserId

      userEntity {
        __typename
        id

        legalName
        firstName
        pronouns
      }
    }
  }
`);

export const getWorkspaces = graphql(`
  query getAllWorkspaces($overrideUserId: Int) {
    getWorkspaces(overrideUserId: $overrideUserId) {
      __typename

      id
      name
      onboardings {
        __typename

        id
        type
        visaClass
      }
    }
  }
`);

export const getOnboardingPublishedCase = graphql(`
  query getOnboardingPublishedCase($id: Int!) {
    onboarding(onboardingId: $id) {
      __typename
      id 
      
      publishedCase {
        __typename
        id
        name
        type
        status
        createdAt
        changelogHtml
        comments
        file {
          __typename
          id
          name
          mimeType
          size
          presignedUrl
          thumbnailUrl
        }
      }
    }
  }
`);

export const getOnboarding = graphql(`
  query getOnboarding($id: Int!) {
    onboarding(onboardingId: $id) {
      __typename
      id
      visaClass
      externalStatus
      receiptNotice
      trackingNumber
      publishedCase {
        __typename
        id
        name
        type
        status
        createdAt
        changelogHtml
        comments
        file {
          __typename
          id
          name
          mimeType
          size
          thumbnailUrl
          presignedUrl
        }
      }

      groups {
        __typename
        id
        nodes {
          __typename
          id
          name
          type
          content
          completed
          schema
          customerCreated
          orderIndex
          internalStatus
          
          # Include files data upfront to avoid separate queries
          files {
            __typename
            id
            name
            mimeType
            size
            subKey
            thumbnailUrl
            presignedUrl
          }
        }
      }
      letterActions {
        __typename
        id
        letterId
        type
        status
        createdAt
        letterName
        recipientType
      }
      formActions {
        __typename
        id
        name
        status
        type
        createdAt
      }
    }
  }
`);

export const getFormAction = graphql(`
  query getFormAction($id: Int!) {
    getFormAction(formActionId: $id) {
      id
      name
      status
      type
      createdAt
      additionalNotes
      baseFile {
        id
        name
        presignedUrl
      }
      signedFile {
        id
        name
        presignedUrl
      }
      pages
      comments
    }
  }
`);

export const getDashboardData = graphql(`
  query getDashboardData($id: Int!, $overrideUserId: Int) {
    onboarding(onboardingId: $id) {
      __typename
      id
      visaClass
    }

    context(userContextInput: { overrideUserId: $overrideUserId }) {
      __typename
      id

      userEntity {
        __typename
        id
        legalName
        firstName
      }
    }
  }
`);

export const getOnboardingNode = graphql(`
  query getOnboardingNode($id: Int!) {
    onboardingNode(onboardingNodeId: $id) {
      __typename
      id
      type
      content
      schema
      completed
      customerCreated
      orderIndex
      internalStatus
    }
  }
`);

export const getOnboardingNodeFiles = graphql(`
  query getOnboardingNodeFiles($id: Int!, $subKey: String) {
    onboardingNodeFiles(onboardingNodeId: $id, subKey: $subKey) {
      __typename
      id
      name
      mimeType
      size
      subKey
      thumbnailUrl
    }
  }
`);

export const updateOnboardingNode = graphql(`
  mutation updateOnboardingNode($input: OnboardingNodeUpdateInput!) {
    updateOnboardingNode(onboardingUpdate: $input) {
      __typename
      id
      content
    }
  }
`);

const startFileUpload = graphql(`
  mutation startFileUpload($input: UploadRequestInput!) {
    startFileUpload(uploadRequest: $input) {
      __typename
      fileId
      uploadUrl
    }
  }
`);

const finishFileUpload = graphql(`
  mutation finishFileUpload($input: FinishUploadRequestInput!) {
    finishFileUpload(finishUploadRequest: $input) {
      __typename
      success
    }
  }
`);

export const unlinkFileMutation = graphql(`
  mutation unlinkFile($input: UnlinkFileRequestInput!) {
    unlinkFile(unlinkFileRequest: $input) {
      __typename
      success
    }
  }
`);

export const useOnboardingNodeFileUploadMutation = () => {
  const { getToken } = useAuth();
  const client = useClient();

  const uploadFile = async (
    files: File[],
    linkRecordId: number,
    linkSubKey: string,
    onProgress?: (fileName: string, progress: number) => void
  ) => {
    for (const file of files) {
      const token = getToken();
      if (token == null) return;

      const startMutation = await client.mutation(startFileUpload, {
        input: {
          fileName: file.name,
          mimeType: file.type,
          linkType: "onboarding-node",
          linkRecordId,
          linkSubKey,
        },
      });

      if (!startMutation.data?.startFileUpload?.fileId) {
        console.error("error starting file upload", startMutation.error);
        return;
      }

      const fileId = startMutation.data.startFileUpload.fileId;
      const fileUploadUrl = startMutation.data.startFileUpload.uploadUrl;

      const xhr = new XMLHttpRequest();
      
      xhr.upload.addEventListener('progress', (event) => {
        if (event.lengthComputable) {
          const progress = Math.round((event.loaded / event.total) * 100);
          onProgress?.(file.name, progress);
        }
      });

      await new Promise<void>((resolve, reject) => {
        xhr.open('PUT', fileUploadUrl);
        xhr.setRequestHeader('Content-Type', file.type);
        
        xhr.onload = () => {
          if (xhr.status === 200) {
            resolve();
          } else {
            reject(new Error(`Upload failed with status ${xhr.status}`));
          }
        };
        
        xhr.onerror = () => reject(new Error('Upload failed'));
        xhr.send(file);
      });

      const finishMutation = await client.mutation(finishFileUpload, {
        input: {
          fileId,
        },
      });

      if (!finishMutation.data?.finishFileUpload?.success) {
        console.error("error finishing file upload", finishMutation.error);
        return;
      }
    }
  };

  return uploadFile;
};

export const markOnboardingNodeAsCompleteMutation = graphql(`
  mutation markOnboardingNodeAsComplete($onboardingNodeId: Int!) {
    completeOnboardingNode(onboardingNodeId: $onboardingNodeId) {
      id
      completed
    }
  }
`);

export const createOnboardingNodeMutation = graphql(`
  mutation createOnboardingNode($input: CreateNodeInput!) {
    createNode(createNodeInput: $input) {
      __typename

      id
      visaClass

      groups {
        __typename

        id

        nodes {
          __typename

          id
          content
          customerCreated
          completed
        }
      }
    }
  }
`);

export const deleteOnboardingNodeMutation = graphql(`
  mutation deleteOnboardingNode($id: Int!) {
    deleteNode(deleteNodeInput: { nodeId: $id })
  }
`);

export const getURLPreview = graphql(`
  query getUrlPreview($url: String!) {
    linkPreview(linkPreviewInput: { url: $url }) {
      __typename
      title
    }
  }
`);

export const subscribeToOnboardingChange = graphql(`
  subscription onboardingChange($id: Int!) {
    onboarding(onboardingId: $id) {
      id
    }
  }
`);

export const subscribeToOnboardingNodeChange = graphql(`
  subscription onboardingNodeChange($id: Int!) {
    onboardingNode(onboardingNodeId: $id) {
      id
    }
  }
`);

export const subscribeToOnboardingNodeFileChange = graphql(`
  subscription onboardingNodeFileChange($id: Int!) {
    onboardingNodeFiles(onboardingNodeId: $id) {
      id
    }
  }
`);

export const submitNodeForReview = graphql(`
  mutation submitNodeForReview($nodeId: Int!) {
    submitNodeForReview(nodeId: $nodeId) {
      __typename
      id
      internalStatus
      completed
    }
  }
`);

export const getLetterActionById = graphql(`
  query getLetterActionById($id: Int!) {
    getLetterAction(letterActionId: $id) {
      __typename
      id
      letterName
      status
      createdAt
      letterId
      recipientType
      beneficiaryId
      companyId
      externalName
      externalEmail
      beneficiaryName
      additionalNotes
      type
    }
  }
`);

export const getLetterActionStatus = graphql(`
  query getLetterActionById($id: Int!) {
    getLetterAction(letterActionId: $id) {
      status
    }
  }
`);

export const getFileById = graphql(`
  query getFileById($id: Int!) {
    getFile(fileId: $id) {
      __typename
      id
      name
      mimeType
      size
      presignedUrl
    }
  }
`);

export const getCompanyWorkspace = graphql(`
  query getCompanyWorkspace($id: Int!) {
    getCompanyWorkspace(companyId: $id) {
      __typename
      id

      entityId
      entityData

      files {
        __typename

        id
        name
        mimeType
        subKey
        size
        thumbnailUrl
      }

      cases {
        __typename
        id
        beneficiaryName
        visaClass
        status
        petitionerRepresentative {
          __typename

          id
          name
        }

        validityStartDate
        validityEndDate

        companyOnboarding {
          __typename

          id
          employerDetailsNodeId
          employerDetailsNodeStatus

          letterActions {
            __typename
            id
            status
          }
          formActions {
            __typename
            id
            status
          }

          publishedCase {
            __typename
            id
            status
            changelogHtml
            createdAt
            comments
            file {
              __typename
              id
              presignedUrl
              size
              name
              mimeType
            }
          }
        }
      }
    }
  }
`);

export const updateCompanyData = graphql(`
  mutation updateCompanyData($input: CompanyEntityDataUpdateInput!) {
    updateCompanyEntityData(entityDataUpdateInput: $input)
  }
`);

export const subscribeToCompanyFileChange = graphql(`
  subscription companyFileChange($companyId: Int!) {
    companyFiles(companyId: $companyId) {
      id
    }
  }
`);

export const useCompanyFileUploadMutation = () => {
  const { getToken } = useAuth();
  const client = useClient();

  const uploadFile = async (
    files: File[],
    linkRecordId: number,
    linkSubKey: string,
    onProgress?: (fileName: string, progress: number) => void
  ) => {
    for (const file of files) {
      const token = getToken();
      if (token == null) return;

      const startMutation = await client.mutation(startFileUpload, {
        input: {
          fileName: file.name,
          mimeType: file.type,
          linkType: "entity",
          linkRecordId,
          linkSubKey,
        },
      });

      if (!startMutation.data?.startFileUpload?.fileId) {
        console.error("error starting file upload", startMutation.error);
        return;
      }

      const fileId = startMutation.data.startFileUpload.fileId;
      const fileUploadUrl = startMutation.data.startFileUpload.uploadUrl;

      const xhr = new XMLHttpRequest();
      
      xhr.upload.addEventListener('progress', (event) => {
        if (event.lengthComputable) {
          const progress = Math.round((event.loaded / event.total) * 100);
          onProgress?.(file.name, progress);
        }
      });

      await new Promise<void>((resolve, reject) => {
        xhr.open('PUT', fileUploadUrl);
        xhr.setRequestHeader('Content-Type', file.type);
        
        xhr.onload = () => {
          if (xhr.status === 200) {
            resolve();
          } else {
            reject(new Error(`Upload failed with status ${xhr.status}`));
          }
        };
        
        xhr.onerror = () => reject(new Error('Upload failed'));
        xhr.send(file);
      });

      const finishMutation = await client.mutation(finishFileUpload, {
        input: {
          fileId,
        },
      });

      if (!finishMutation.data?.finishFileUpload?.success) {
        console.error("error finishing file upload", finishMutation.error);
        return;
      }
    }
  };

  return uploadFile;
};

export const getCompanyMembers = graphql(`
  query getCompanyMembers($id: Int!) {
    getCompanyWorkspace(companyId: $id) {
      __typename
      id

      members {
        __typename
        id
        members {
          __typename

          id
          email
          role

          userEntity {
            __typename
            id
            legalName
            role
            phoneNumber
          }
        }
      }
    }
  }
`);

export const inviteCompanyMember = graphql(`
  mutation inviteCompanyMember($input: InviteUserToCompanyInput!) {
    inviteUserToCompany(inviteUserToCompanyInput: $input) {
      __typename
      id
      members {
        id
        __typename

        email
        role
      }
    }
  }
`);

export const updateCompanyMemberRole = graphql(`
  mutation updateCompanyMemberRole($input: ChangeCompanyMemberRoleInput!) {
    changeCompanyMemberRole(changeCompanyMemberRoleInput: $input) {
      __typename
      id
      members {
        id
        __typename
        email
        role
      }
    }
  }
`);

export const patchUserEntityMutation = graphql(`
  mutation updateUserEntity($input: PatchUserEntityInput!) {
    patchUserEntity(patchUserEntityInput: $input) {
      __typename
      id
      legalName
      role
      firstName
      lastName
      phoneNumber
    }
  }
`);

export const getWorkspaceInvite = graphql(`
  query getworkspaceInvite($input: WorkspaceInviteInput!) {
    workspaceInvite(workspaceInviteInput: $input) {
      __typename
      id
      companyId
      name
      accepted
    }
  }
`);

export const markInviteAsAccepted = graphql(`
  mutation markInviteAsAccepted($input: MarkWorkspaceInviteAsAcceptedInput!) {
    markWorkspaceInviteAsAccepted(markWorkspaceInviteAsAcceptedInput: $input)
  }
`);

export const approveLetterAction = graphql(`
  mutation approveLetterAction($letterActionId: Int!) {
    approveLetterAction(letterActionId: $letterActionId)
  }
`);

export const requestLetterActionChanges = graphql(`
  mutation requestLetterActionChanges($letterActionId: Int!) {
    requestLetterActionChanges(letterActionId: $letterActionId)
  }
`);

export const updateReceiptNotice = graphql(`
  mutation updateReceiptNotice($input: UpdateReceiptNoticeInput!) {
    updateReceiptNotice(input: $input) {
      __typename
      id
      receiptNotice
    }
  }
`);

export const getFollowUpAction = graphql(`
  query getFollowUpAction($letterActionId: Int!) {
    getFollowUpAction(letterActionId: $letterActionId)
  }
`);

export const getPendingLetterReviews = graphql(`
  query getPendingLetterReviews($letterId: Int!) {
    getPendingLetterReviews(letterId: $letterId) {
      id
      externalName
      externalEmail
      status
      recipientType
    }
  }
`);

export const getCase = graphql(`
  query getEmployeeCase($caseId: Int!) {
    getEmployeeCase(caseId: $caseId) {
      __typename
      id
      visaClass
      status

      company {
        id
      }

      petitionerRepresentative {
        id
        name
      }
      beneficiaryName

      validityStartDate
      validityEndDate

      companyOnboarding {
        id
        employerDetailsNodeStatus
        employerDetailsNodeId
      }
    }
  }
`);

export const getRecipientNameByLetterId = graphql(`
  query getRecipientNameByLetterId($letterActionId: Int!) {
    getLetterActionRecipientName(letterActionId: $letterActionId)
  }
`);

export const InviteExternalReviewer = graphql(`
  mutation inviteExternalReviewer($letterActionId: Int!, $sendEmail: Boolean!) {
    inviteExternalReviewer(
      letterActionId: $letterActionId
      sendEmail: $sendEmail
    )
  }
`);

export const getPetitionerRepSelectorData = graphql(`
  query getPetitionerRepSelectorData($caseId: Int!, $companyId: Int!) {
    getCompanyWorkspace(companyId: $companyId) {
      __typename
      id

      members {
        __typename
        id
        members {
          __typename

          id
          name
          email
          role

          userEntity {
            __typename
            id
            legalName
            role
            phoneNumber
          }
        }
      }
    }

    getEmployeeCase(caseId: $caseId) {
      petitionerRepresentative {
        id
        name
      }
    }
  }
`);

export const updatePetitionerRep = graphql(`
  mutation updatePetitionerRepresentative(
    $input: UpdatePetitionerRepresentativeInput!
  ) {
    updatePetitionerRepresentative(input: $input) {
      petitionerRepresentative {
        id
        name
      }
    }
  }
`);

export const useFormFileUploadMutation = () => {
  const { getToken } = useAuth();
  const client = useClient();

  const uploadFile = async (
    file: File,
    linkRecordId: number,
    linkSubKey: string,
    onProgress?: (fileName: string, progress: number) => void
  ) => {
    const token = getToken();
    if (token == null) return;

    const startMutation = await client.mutation(startFileUpload, {
      input: {
        fileName: file.name,
        mimeType: file.type,
        linkType: "form",
        linkRecordId,
        linkSubKey,
      },
    });

    if (!startMutation.data?.startFileUpload?.fileId) {
      console.error("error starting file upload", startMutation.error);
      return;
    }

    const fileId = startMutation.data.startFileUpload.fileId;
    const fileUploadUrl = startMutation.data.startFileUpload.uploadUrl;

    const xhr = new XMLHttpRequest();
      
    xhr.upload.addEventListener('progress', (event) => {
      if (event.lengthComputable) {
        const progress = Math.round((event.loaded / event.total) * 100);
        onProgress?.(file.name, progress);
      }
    });

    await new Promise<void>((resolve, reject) => {
      xhr.open('PUT', fileUploadUrl);
      xhr.setRequestHeader('Content-Type', file.type);
      
      xhr.onload = () => {
        if (xhr.status === 200) {
          resolve();
        } else {
          reject(new Error(`Upload failed with status ${xhr.status}`));
        }
      };
      
      xhr.onerror = () => reject(new Error('Upload failed'));
      xhr.send(file);
    });

    const finishMutation = await client.mutation(finishFileUpload, {
      input: {
        fileId,
      },
    });

    if (!finishMutation.data?.finishFileUpload?.success) {
      console.error("error finishing file upload", finishMutation.error);
      return;
    }

    return fileId;
  };

  return uploadFile;
};

export const approveFormAction = graphql(`
  mutation approveFormAction($input: ApproveFormActionInput!) {
    approveFormAction(approveFormActionInput: $input) {
      id
      status
      signedFile {
        id
        name
        presignedUrl
      }
      comments
    }
  }
`);

export const requestFormActionChanges = graphql(`
  mutation requestFormActionChanges($input: RequestFormActionChangesInput!) {
    requestFormActionChanges(requestFormActionChangesInput: $input) {
      id
      status
      signedFile {
        id
        name
        presignedUrl
      }
      comments
    }
  }
`);

export const createSupportRequest = graphql(`
  mutation createSupportRequest($input: CreateSupportRequestInput!) {
    createSupportRequest(createSupportRequestInput: $input) {
      id
    }
  }
`);

export const updateCompiledCaseAction = graphql(`
  mutation updateCompiledCaseAction($input: UpdateCompiledCaseActionInput!) {
    updateCompiledCaseAction(updateCompiledCaseActionInput: $input) {
      id
      status
      comments
    }
  }
`);

export const approvedCompiledCaseAction = graphql(`
  mutation approvedCompiledCaseAction($input: ApproveCompiledCaseActionInput!) {
    approveCompiledCaseAction(approveCompiledCaseActionInput: $input) {
      id
      status
      comments
    }
  }
`);

export const getFileThumbnail = graphql(`
  query getFileThumbnail($fileId: Int!) {
    getFile(fileId: $fileId) {
      __typename
      id
      thumbnailUrl
      presignedUrl
      mimeType
    }
  }
`);
