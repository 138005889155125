import { Spinner } from "@radix-ui/themes";
import { useRef, useState, useEffect } from "react";
import Dropzone from "react-dropzone";
import { UploadIcon } from "./icons/upload";
import { InputText, LabelText } from "./typography";
import { TrashIcon } from "./icons/trash";
import { cn } from "@/lib/cn";
import { FileViewerModal } from "./fileViewer";
import { useQuery } from "urql";
import { getFileThumbnail } from "@/lib/queries";
import { customToast } from "./toast";
import { WarningIcon } from "./icons/warning";

const getFileTypeHint = (mimeType?: string) => {
  if (mimeType === "application/pdf") return "PDF";
  if (mimeType === "image/jpeg") return "JPG";
  if (mimeType === "image/png") return "PNG";
  if (mimeType === "application/vnd.ms-excel") return "XLS";
  if (
    mimeType ===
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
  )
    return "DOC";
};

const CircularProgress = ({ progress }: { progress: number }) => {
  const radius = 20;
  const circumference = 2 * Math.PI * radius;
  const strokeDashoffset = circumference - (progress / 100) * circumference;

  return (
    <div className="absolute inset-0 flex items-center justify-center">
      <svg className="transform -rotate-90 w-12 h-12">
        {/* Background circle */}
        <circle
          className="text-grey-700"
          strokeWidth="3"
          stroke="currentColor"
          fill="transparent"
          r={radius}
          cx="24"
          cy="24"
        />
        {/* Progress circle */}
        <circle
          className="text-blue text-opacity-50 transition-all duration-300 ease-out"
          strokeWidth="3"
          strokeDasharray={circumference}
          strokeDashoffset={strokeDashoffset}
          strokeLinecap="round"
          stroke="currentColor"
          fill="transparent"
          r={radius}
          cx="24"
          cy="24"
        />
      </svg>
    </div>
  );
};

export const FileCard = (props: {
  file: {
    id: number;
    name: string;
    size?: number | null;
    mimeType?: string;
    thumbnailUrl?: string;
    presignedUrl?: string;
  };
  onRemove?: () => void;
  uploading?: boolean;
  progress?: number;
}) => {
  const { file, onRemove, uploading, progress } = props;

  const doRemove = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onRemove?.();
  };

  const [fileViewerOpen, setFileViewerOpen] = useState(false);
  const [showPlaceholder, setShowPlaceholder] = useState<boolean>(true);
  const fileTypeHint = getFileTypeHint(file.mimeType);

  // Fetch file URLs if we have a valid file ID and not uploading
  const [{ data: fileData }] = useQuery({
    query: getFileThumbnail,
    variables: { fileId: file.id },
    pause: file.id === -1 || uploading, // Don't fetch while uploading or for temporary files
  });

  // Use the thumbnail URL if available, otherwise try presigned URL for images
  const thumbnailSrc =
    (file.mimeType?.startsWith("image/")
      ? fileData?.getFile?.presignedUrl
      : fileData?.getFile?.thumbnailUrl) ?? undefined;

  // Don't show placeholder if we're transitioning from uploading state and have a URL
  useEffect(() => {
    if (!uploading && file.thumbnailUrl) {
      setShowPlaceholder(false);
    }
  }, [uploading, file.thumbnailUrl]);

  return (
    <>
      <FileViewerModal
        open={fileViewerOpen}
        setOpen={(v) => setFileViewerOpen(v)}
        fileId={file.id}
      />
      <div
        className="flex flex-col gap-2 w-[167px] max-w-[167px] min-w-[167px] min-h-[206px] bg-grey-200 rounded-lg p-2 cursor-pointer"
        onClick={() => setFileViewerOpen(true)}
      >
        <div className="relative rounded-sm h-[150px] bg-grey-400 group z-10">
          <div
            className={cn(
              "rounded-sm h-[150px] bg-grey-400 group",
              uploading && "pointer-events-none"
            )}
          >
            <div className="w-full h-full bg-grey-800 rounded-sm shadow-lg items-center">
              {showPlaceholder && (
                <div className="flex flex-col px-3 gap-2 h-full py-2 justify-center">
                  <div className="w-3/4 h-2 bg-grey-500 rounded-full"></div>
                  <div className="w-full h-2 bg-grey-500 rounded-full"></div>
                  <div className="w-5/6 h-2 bg-grey-500 rounded-full"></div>
                  <div className="w-2/3 h-2 bg-grey-500 rounded-full"></div>
                  <div className="w-3/4 h-2 bg-grey-500 rounded-full"></div>
                  <div className="w-full h-2 bg-grey-500 rounded-full"></div>
                  <div className="w-5/6 h-2 bg-grey-500 rounded-full"></div>
                  <div className="w-5/6 h-2 bg-grey-500 rounded-full"></div>
                </div>
              )}
              <img
                className={cn(
                  "w-full h-full object-cover p-4",
                  showPlaceholder && "hidden"
                )}
                src={thumbnailSrc}
                onLoad={() => {
                  setShowPlaceholder(false);
                }}
              />
            </div>
          </div>
          <div className="relative h-[150px] z-20 -my-[150px]">
            <div className="w-full flex flex-col items-end p-1 invisible group-hover:visible top-0 left-0">
              <button
                className="p-2 bg-grey-150 rounded-md backdrop-blur-lg"
                onClick={doRemove}
              >
                <TrashIcon className="fill-negative" />
              </button>
            </div>

            {uploading && (
              <div className="absolute inset-0 flex items-center justify-center">
                {progress !== undefined ? (
                  <CircularProgress progress={progress} />
                ) : (
                  <Spinner />
                )}
              </div>
            )}

            {fileTypeHint != null && (
              <div className="h-full flex flex-col">
                <div className="py-0.5 px-1.5 bg-grey-150 rounded-md backdrop-blur-lg mr-auto mt-auto mb-10 ml-1 text-grey-500 text-sm">
                  {fileTypeHint}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="px-1 flex flex-col gap-1">
          <LabelText className="text-grey-500 text-sm truncate">
            {file.name}
          </LabelText>

          <LabelText className="text-grey-400">
            {file.size != null && `${Math.round(file.size / 1024)} KB`}
            {file.size == null && "Unknown size"}
          </LabelText>
        </div>
      </div>
    </>
  );
};

export const FileUploadArea = (props: {
  files?: {
    id: number;
    name: string;
    size?: number | null;
    mimeType: string;
    thumbnailUrl?: string;
  }[];
  onUpload: (
    files: File[],
    onProgress?: (fileName: string, progress: number) => void
  ) => Promise<void>;
  disabled?: boolean;
  multiple?: boolean;
  onRemove?: (id: number) => Promise<void>;
}) => {
  const { files, onUpload, disabled, multiple, onRemove } = props;

  // Track IDs of files being deleted for optimistic UI
  const [deletingFileIds, setDeletingFileIds] = useState<Set<number>>(
    new Set()
  );

  const handleRemove = async (id: number) => {
    if (!onRemove) return;
    // Find the file being deleted
    const file = files?.find((f) => f.id === id);
    if (!file) return;

    // Optimistically mark file as being deleted
    setDeletingFileIds((prev) => new Set([...prev, id]));

    setUploadingFiles((prev) => {
      const next = { ...prev };
      delete next[file.name];
      return next;
    });

    try {
      await onRemove(id);
    } catch (error) {
      setDeletingFileIds((prev) => {
        const next = new Set(prev);
        next.delete(id);
        return next;
      });

      customToast(
        "Failed to remove file",
        <WarningIcon />,
        "Could not unlink file, please try again."
      );
    }
  };

  useEffect(() => {
    //  remove files that are no longer in the files prop from deletingFileIds
    setDeletingFileIds((prev) => {
      const next = new Set(prev);
      prev?.forEach((file) => {
        if (!files?.some((f) => f.id === file)) {
          next.delete(file);
        }
      });
      return next;
    });
  }, [files]);

  const [uploadingFiles, setUploadingFiles] = useState<{
    [key: string]: {
      name: string;
      size: number;
      mimeType: string;
      progress: number;
    };
  }>({});

  const inputRef = useRef<HTMLInputElement>(null);

  const doUpload = async (acceptedFiles: File[]) => {
    if (acceptedFiles.length === 0) return;

    setUploadingFiles((prev) => {
      const newFiles = { ...prev };
      acceptedFiles.forEach((file) => {
        newFiles[file.name] = {
          name: file.name,
          size: file.size,
          mimeType: file.type,
          progress: 0,
        };
      });
      return newFiles;
    });

    try {
      const updateProgress = (fileName: string, progress: number) => {
        setUploadingFiles((prev) => ({
          ...prev,
          [fileName]: {
            ...prev[fileName],
            progress,
          },
        }));
      };

      await onUpload(acceptedFiles, updateProgress);

      // After successful upload, mark files as complete but let filesToRender handle cleanup
      setUploadingFiles((prev) => {
        const next = { ...prev };
        acceptedFiles.forEach((file) => {
          if (next[file.name]) {
            next[file.name].progress = 100;
          }
        });
        return next;
      });
    } catch (error) {
      // On error, remove the uploading files
      setUploadingFiles((prev) => {
        const remaining = { ...prev };
        acceptedFiles.forEach((file) => {
          delete remaining[file.name];
        });
        customToast(
          "Failed to upload files",
          <WarningIcon />,
          "Failed to upload files, please try again."
        );
        return remaining;
      });
    }
  };

  const filesToRender = [
    ...(files ?? [])
      .filter((file) => !deletingFileIds.has(file.id))
      .map((file) => ({
        ...file,
        progress: undefined as number | undefined,
        uploading: false,
      })),
    ...Object.entries(uploadingFiles)
      .filter(([fileName]) => {
        // Show uploading files if:
        // 1. The file is not in the files prop yet, or
        // 2. The file is in deletingFileIds (being deleted)
        const existingFile = files?.find((f) => f.name === fileName);
        return !existingFile || deletingFileIds.has(existingFile.id);
      })
      .map(([_, file]) => ({
        id: -1,
        name: file.name,
        size: file.size,
        mimeType: file.mimeType,
        thumbnailUrl: undefined,
        uploading: true,
        progress: file.progress,
      })),
  ];

  return (
    <div
      className={cn(
        "flex flex-row gap-[10px] h-full w-full border border-dashed border-grey-500 rounded-lg flex-wrap overflow-scroll",
        filesToRender.length > 0 && "bg-grey-700 p-2",
        filesToRender.length < 3 && "flex-nowrap"
      )}
    >
      <Dropzone
        onDrop={doUpload}
        accept={{
          "application/pdf": [".pdf"],
          "image/jpeg": [".jpg", ".jpeg"],
          "image/png": [".png"],
        }}
        maxSize={100 * 1024 * 1024 /* 100MB */}
        disabled={disabled || (!multiple && (files?.length ?? 0) > 0)}
        multiple={multiple}
      >
        {({ getRootProps, getInputProps }) => (
          <>
            <div
              className={cn(
                "flex flex-col min-w-[167px] min-h-[206px] items-center justify-center bg-white rounded-lg",
                filesToRender.length === 0 && "w-full",
                filesToRender.length === 1 && "w-full gap-1",
                filesToRender.length > 2 && "w-[167px] gap-1",
                "sm:px-1 md:px-2 lg:px-3"
              )}
              {...getRootProps()}
            >
              <input ref={inputRef} {...getInputProps()} />

              {!multiple &&
                filesToRender.length > 0 &&
                filesToRender[0].id != null && (
                  <div className="px-1 flex flex-col items-center text-center">
                    Delete existing file to upload a new one.
                    <LabelText className="text-center text-grey-400">
                      Minimum 1600px width recommended. Max 100MB each.
                      <br />
                      Acceptable file types include jpg, png, pdf.
                    </LabelText>
                  </div>
                )}

              {((!multiple && filesToRender.length === 0) || multiple) && (
                <>
                  <div className="rounded-full p-1 bg-blue-light">
                    <UploadIcon />
                  </div>
                  <div className="px-1 flex flex-col items-center text-center">
                    <InputText>
                      Drag and drop or{" "}
                      <button
                        className="text-blue"
                        onClick={() => inputRef.current?.click()}
                      >
                        Browse
                      </button>
                    </InputText>
                    <LabelText className="text-center text-grey-400">
                      Minimum 1600px width recommended. Max 100MB each.
                      <br />
                      Acceptable file types include jpg, png, pdf.
                    </LabelText>
                  </div>
                </>
              )}
            </div>

            {filesToRender.length > 0 && (
              <>
                {filesToRender.map((file) => (
                  <FileCard
                    key={file.name}
                    file={{
                      id: file.id,
                      name: file.name,
                      size: file.size,
                      mimeType: file.mimeType,
                      thumbnailUrl: file.thumbnailUrl,
                      presignedUrl: undefined,
                    }}
                    uploading={file.id === -1}
                    progress={file.progress}
                    onRemove={
                      file.id !== -1 ? () => handleRemove(file.id) : undefined
                    }
                  />
                ))}
              </>
            )}
          </>
        )}
      </Dropzone>
    </div>
  );
};
