import { getOnboarding } from "@/lib/queries";
import { ResultOf } from "gql.tada";
import { useState } from "react";

import moment from "moment";
import { CheckCircledIcon } from "@radix-ui/react-icons";

import { PetitionReviewModal } from "./petitionReviewModal";
import { Button } from "./button";
import { useTutorialModalState } from "@/lib/hooks";
import { PetitionReviewTutorialModal } from "./tutorials/petitionReview";
import { cn } from "@/lib/cn";

export const PetitionPreviewCard = (props: {
  compiledCaseAction: NonNullable<
    ResultOf<typeof getOnboarding>["onboarding"]["publishedCase"][0]
  >;
}) => {
  const { compiledCaseAction: petition } = props;
  const { status, type, createdAt, name } = petition;

  const [modalOpen, setModalOpen] = useState(false);

  const tutorialShown = useTutorialModalState(
    (state) => state.petitionReview.modalShown
  );

  const [showPlaceholder, setShowPlaceholder] = useState(true);

  return (
    <div className="w-[500px] rounded-lg bg-grey-200 p-3 gap-4 flex flex-row items-center">
      {modalOpen && <PetitionReviewTutorialModal />}

      {tutorialShown && (
        <PetitionReviewModal
          open={modalOpen}
          setOpen={setModalOpen}
          publishedCase={petition}
        />
      )}

      <div className="w-[45%] h-[90px] bg-[#666666] bg-opacity-[56%] rounded-sm flex flex-col items-center justify-center overflow-hidden">
        {showPlaceholder && (
          <div className="w-[80%] h-full bg-white rounded-sm shadow-lg mt-10 p-3 pt-5 text-[3px] text-wrap break-words">
            ~~~~~~~~
            <br />
            <br />
            ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
            <br />
            ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
            <br />
            <br />
            ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
            <br />
          </div>
        )}
        {petition.file.thumbnailUrl != null && (
          <img
            src={petition.file.thumbnailUrl}
            className={cn(
              "w-full h-full object-cover p-4",
              showPlaceholder && "hidden"
            )}
            onLoadedData={() => setShowPlaceholder(false)}
          />
        )}
      </div>
      <div className="h-full w-full flex flex-col gap-1">
        <div className="w-full flex flex-row justify-between items-center text-nowrap">
          <div className="text-sm text-grey-400">
            Shared {moment(createdAt).fromNow()}
          </div>
          {type === "review" && status === "pending" && (
            <div className="px-3 py-[3px] text-[#81CA67] text-[11px] font-medium bg-[#81CA67] bg-opacity-10 rounded-full ring-1 ring-[#81CA67] ring-opacity-25 shadow-positive-2 text-nowrap">
              Ready to sign
            </div>
          )}
          {type === "review" && status === "changes_requested" && (
            <div className="px-3 py-[3px] text-[#caab67] text-[11px] font-medium bg-[#caab67] bg-opacity-10 rounded-full ring-1 ring-[#caab67] ring-opacity-25 shadow-positive-2 text-nowrap">
              Changes Requested
            </div>
          )}
          {type === "review" && status === "approved" && (
            <div className="flex flex-row gap-1 items-center px-3 py-[3px] text-[#5da045] text-[11px] font-medium bg-[#5da045] bg-opacity-10 rounded-full ring-1 ring-[#5da045] ring-opacity-25 shadow-positive-2 text-nowrap">
              <CheckCircledIcon /> <span>Approved</span>
            </div>
          )}
        </div>

        <div className="text-md text-grey-800">{name}</div>

        <div className="mt-auto">
          <Button
            variant="secondary"
            onClick={() => setModalOpen(true)}
            className="px-2 py-1"
          >
            <span className="font-medium">
              {petition.type === "review" && petition.status === "pending"
                ? "Review"
                : "View"}
            </span>
          </Button>
        </div>
      </div>
    </div>
  );
};
