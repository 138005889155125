import { getLetterActionById } from "@/lib/queries";
import { useQuery } from "urql";
import { LetterSignatureEditor } from "./sign";
import { Modal } from "../modal";
import { LetterReviewEditor } from "./review";
import { useState } from "react";

export const LetterModal = (props: {
  open: boolean;
  setOpen: (value: boolean) => void;
  letterActionId: number;
}) => {
  const { letterActionId, setOpen, open } = props;

  const [currentLetterActionId, setCurrentLetterActionId] =
    useState(letterActionId);
  const [hasJustFinishedReview, setHasJustFinishedReview] = useState(false);

  const [{ data }, refetch] = useQuery({
    query: getLetterActionById,
    variables: { id: currentLetterActionId },
  });

  const mode = data?.getLetterAction.type;

  const doRefetch = () => {
    refetch({ requestPolicy: "network-only" });
  };

  return (
    <Modal
      open={open}
      onOpenChange={setOpen}
      title="Review support letters"
      borderUnderTitle
      titleClassName="bg-grey-800"
      contentClassName="w-[95vw] h-[95vh] max-h-[90vw] max-w-[95vw] bg-grey-700"
    >
      {mode === "sign" && data && (
        <LetterSignatureEditor
          data={data}
          reviewed={hasJustFinishedReview}
          key={`sign-${JSON.stringify(data.getLetterAction)}`}
        />
      )}
      {mode === "reviewApprove" && data && (
        <LetterReviewEditor
          data={data}
          onNextLetterAction={(letterActionId: number) => {
            setCurrentLetterActionId(letterActionId);
            setHasJustFinishedReview(true);
          }}
          key={`review-${JSON.stringify(data.getLetterAction)}`}
          refetch={doRefetch}
        />
      )}
    </Modal>
  );
};
