import { useMutation, useQuery } from "urql";
import { Modal } from "./modal";
import {
  approveFormAction,
  getFileById,
  getFormAction,
  requestFormActionChanges,
  useFormFileUploadMutation,
} from "@/lib/queries";
import { Spinner } from "@radix-ui/themes";
import { ResultOf } from "gql.tada";
import { Button } from "./button";
import {
  ArrowUpIcon,
  CheckCircledIcon,
  Cross1Icon,
  DownloadIcon,
  PlusIcon,
} from "@radix-ui/react-icons";
import { useAuth } from "@clerk/clerk-react";
import { useRef, useState } from "react";
import { customerApi } from "@lighthouse/api";
import { Document, Page } from "react-pdf";
import { useResizeObserver } from "@/lib/hooks";
import { cn } from "@/lib/cn";

import { LoadingOverlay } from "./loadingOverlay";
import { Editor, EditorContent, Extensions, useEditor } from "@tiptap/react";
import Link from "@tiptap/extension-link";
import Underline from "@tiptap/extension-underline";
import StarterKit from "@tiptap/starter-kit";
import Placeholder from "@tiptap/extension-placeholder";
import { FiEdit, FiTrash } from "react-icons/fi";
import { Tooltip, TooltipContent, TooltipTrigger } from "./tooltip";
import moment from "moment";
import { useRerenderOnEditorChange } from "@lighthouse/editor";
import { ErrorBoundary } from "@sentry/react";

const extensions: Extensions = [
  StarterKit,
  Underline,
  Link,
  Placeholder.configure({
    emptyEditorClass: "is-editor-empty",
    placeholder: "Add your comments...",
  }),
];

const SubmitFormButton = (props: {
  data: ResultOf<typeof getFormAction>["getFormAction"];
  signedPages: Record<number, number>;
}) => {
  const { data, signedPages } = props;

  const [loading, setLoading] = useState(false);

  const approveFormActionMutation = useMutation(approveFormAction)[1];

  const disabled = Object.keys(signedPages).length !== data.pages.length;

  const approveForm = async () => {
    if (
      loading ||
      data.id == null ||
      Object.keys(signedPages).length !== data.pages.length
    )
      return;

    setLoading(true);

    await approveFormActionMutation({
      input: {
        formActionId: data.id,
        pages: Object.keys(signedPages).map((pageNum) => {
          return {
            page: parseInt(pageNum),
            fileId: signedPages[parseInt(pageNum)],
          };
        }),
      },
    });

    setLoading(false);
  };

  return (
    <>
      <LoadingOverlay isLoading={loading} />

      {data.status === "signed" && (
        <Button
          variant="primary"
          disabled
          className="disabled:bg-grey-100 disabled:text-grey-400 disabled:shadow-primary-button"
        >
          Form Approved
        </Button>
      )}

      {data.status !== "signed" && (
        <Button
          variant="primary"
          disabled={disabled || loading}
          onClick={approveForm}
          className="disabled:bg-grey-100 disabled:text-grey-400 disabled:shadow-primary-button"
        >
          Approve Form
        </Button>
      )}
    </>
  );
};

const ErrorFallback = () => {
  return (
    <div className="flex flex-col items-center justify-center w-full h-full">
      <p className="text-center text-sm text-grey-300">
        Error loading document, the Lighthouse team has been notified. <br />
        Please try again later.
      </p>
    </div>
  );
};

const SubmitCommentButton = (props: {
  editor: Editor;
  formActionId: number;
}) => {
  const { editor, formActionId } = props;

  useRerenderOnEditorChange(editor);

  const requestFormActionChangesMutation = useMutation(
    requestFormActionChanges
  )[1];

  const [loading, setLoading] = useState(false);

  const submitComment = async () => {
    const content = editor.getHTML();

    if (content === "") return;

    setLoading(true);

    await requestFormActionChangesMutation({
      input: {
        formActionId: formActionId,
        comments: content,
      },
    });

    setLoading(false);
  };

  return (
    <>
      <LoadingOverlay isLoading={loading} />

      <button
        onClick={submitComment}
        disabled={editor.isEmpty}
        className="text-xs w-8 h-8 flex items-center justify-center rounded-[8px] hover:cursor-pointer disabled:cursor-not-allowed shadow-button disabled:bg-grey-700 disabled:text-grey-500 transition-all duration-300 bg-grey-100 text-grey-800 hover:bg-grey-200 shadow-primary-button mt-2 disabled:shadow-none"
      >
        <ArrowUpIcon />
      </button>
    </>
  );
};

const CommentRenderer = (props: {
  data: ResultOf<typeof getFormAction>["getFormAction"];
}) => {
  const { data } = props;

  const [editing, setEditing] = useState(false);

  const editor = useEditor(
    {
      editorProps: {
        attributes: {
          class: "prose prose-sm",
        },
      },
      autofocus: false,
      content: data.comments,
      editable: data.status === "sent",
      extensions: extensions,
    },
    [extensions, data]
  );

  const enableEditing = () => {
    setEditing(true);
    editor?.setEditable(true);
    editor?.commands.focus();
  };

  const discardEdits = () => {
    setEditing(false);
    editor?.setEditable(false);
    editor?.commands.setContent(data.comments);
  };

  if (data.status === "signed") return;

  return (
    <div className="w-full min-w-[300px] flex flex-col bg-grey-800 rounded-lg transition-all group/container ring-2 ring-grey-600 ring-inset">
      <div className="flex flex-col gap-1 p-4">
        <span className="text-xs text-grey-200 font-medium">
          {data.status === "sent" ? "Request Changes" : "Changes Requested"}
        </span>

        <span className="text-xs text-grey-400">
          {data.status === "changes_requested" &&
            "We have recieved your comments below on this form. We will review them and get back to you with changes."}

          {data.status === "sent" &&
            "If you have any comments on this form please enter them below and submit, we will review them and get back to you with changes."}
        </span>

        {data.status === "changes_requested" && !editing && (
          <button
            onClick={enableEditing}
            className="w-fit text-xs text-grey-200 flex flex-row items-center gap-1.5 mt-1"
          >
            <FiEdit />
            <span>Edit comment</span>
          </button>
        )}
      </div>

      <div className="w-full h-[1px] bg-grey-600"></div>

      <div className="flex flex-col p-4 py-3">
        <div className="w-full">
          <EditorContent editor={editor} className="w-full flex-grow-0" />
        </div>

        <div className="flex flex-row justify-end gap-2 items-end">
          {editing && (
            <Tooltip>
              <TooltipTrigger asChild>
                <button
                  onClick={discardEdits}
                  className="text-xs w-8 h-8 flex items-center justify-center rounded-[8px] shadow-button bg-grey-800 text-grey-100 hover:bg-[#E9E9E9] shadow-primary-button"
                >
                  <FiTrash />
                </button>
              </TooltipTrigger>
              <TooltipContent side="top" sideOffset={5} className="p-2">
                Discard edit
              </TooltipContent>
            </Tooltip>
          )}

          {editor != null && (data.status === "sent" || editing) && (
            <SubmitCommentButton editor={editor} formActionId={data.id} />
          )}
        </div>
      </div>
    </div>
  );
};

const FileRenderer = (props: { url: string }) => {
  const { url } = props;

  const [numPages, setNumPages] = useState(0);

  const documentRef = useRef<HTMLDivElement>(null);

  const { width } = useResizeObserver(documentRef);

  const onDocumentLoadSuccess = (numPages: number) => {
    setNumPages(numPages);
  };

  const pages = Array.from({ length: numPages }, (_, i) => i);

  return (
    <Document
      loading={<Spinner className="w-3 h-3 animate-spin text-grey-400" />}
      className="w-full flex flex-col gap-5 items-center overflow-y-auto overflow-x-hidden pb-8 no-scrollbar"
      inputRef={documentRef}
      file={url}
      onLoadSuccess={(x) => onDocumentLoadSuccess(x.numPages)}
    >
      {pages.map((pageNum) => {
        return (
          <Page
            pageIndex={pageNum}
            width={0.984 * width}
            loading={null}
            renderAnnotationLayer={false}
            renderTextLayer={false}
            renderForms={false}
            className="rounded-md border border-grey-600 p-1 shadow-border"
          />
        );
      })}
    </Document>
  );
};

const FileUploadInput = (props: {
  signedPages: Record<number, number>;
  setSignedPages: React.Dispatch<React.SetStateAction<Record<number, number>>>;
  pageNum: number;
  formActionId: number;
}) => {
  const { signedPages, formActionId, pageNum, setSignedPages } = props;

  const [uploading, setUploading] = useState(false);

  const [{ data, fetching }] = useQuery({
    query: getFileById,
    variables: {
      id: signedPages[pageNum],
    },
    pause: signedPages[pageNum] == null,
  });

  const uploadFileMutation = useFormFileUploadMutation();

  const uploadFile = async (
    pageNum: number,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (e.target.files == null || e.target.files.length === 0) return;

    const file = e.target.files[0];

    setUploading(true);

    const res = await uploadFileMutation(
      file,
      formActionId,
      pageNum.toString()
    );

    setUploading(false);

    if (res == null) return;

    setSignedPages((prev) => ({
      ...prev,
      [pageNum]: res,
    }));
  };

  const removeFile = () => {
    setSignedPages((prev) => {
      const newPages = { ...prev };
      delete newPages[pageNum];
      return newPages;
    });
  };

  if (uploading)
    return (
      <div className="cursor-pointer w-[100px] h-[100px] border-dashed border border-grey-500 rounded-lg flex items-center justify-center flex-shrink-0">
        <Spinner className="w-3 h-3 animate-spin text-grey-400" />
      </div>
    );

  if (signedPages[pageNum])
    return (
      <div className="w-[100px] h-[100px] border border-grey-500 rounded-lg flex justify-center flex-shrink-0 relative group bg-grey-400 overflow-visible">
        <button
          onClick={removeFile}
          className="absolute top-0 right-0 translate-x-1/2 -translate-y-1/2 bg-grey-200 shadow-grey-300 rounded-full z-10 flex items-center justify-center h-fit w-fit p-1"
        >
          <Cross1Icon className="w-2 h-2 text-grey-700" />
        </button>

        {fetching && (
          <div className="flex w-full h-full items-center justify-center">
            <Spinner className="w-3 h-3 animate-spin text-grey-200" />
          </div>
        )}

        {data && data.getFile.presignedUrl != null && (
          <ErrorBoundary fallback={ErrorFallback}>
            <Document
              loading={null}
              className="mt-4 rounded overflow-hidden"
              file={data.getFile.presignedUrl}
            >
              <Page
                pageIndex={0}
                width={80}
                className="!bg-grey-400"
                error={
                  <div className="mx-5 mt-5 text-center leading-3 text-[10px] text-grey-600">
                    Failed to load file
                  </div>
                }
                loading={null}
                renderAnnotationLayer={false}
                renderTextLayer={false}
                renderForms={false}
              />
            </Document>
          </ErrorBoundary>
        )}
      </div>
    );

  return (
    <>
      <input
        type="file"
        multiple
        hidden
        id={`file-upload-${pageNum}`}
        onChange={(e) => uploadFile(pageNum, e)}
        accept=".pdf"
      />

      <label
        htmlFor={`file-upload-${pageNum}`}
        className="cursor-pointer w-[100px] h-[100px] border-dashed border border-grey-500 rounded-lg flex items-center justify-center flex-shrink-0 flex-col"
      >
        <div className="w-[25px] h-[25px] bg-grey-500 rounded-full flex items-center justify-center opacity-8 mt-4">
          <PlusIcon className="text-grey-300" />
        </div>

        <span className="text-[10px] text-grey-400 font-light mt-2 h-4">
          Page {pageNum}
        </span>
      </label>
    </>
  );
};

const FormViewer = (props: { formActionId: number }) => {
  const { formActionId } = props;

  const [{ data, fetching }] = useQuery({
    query: getFormAction,
    variables: {
      id: formActionId,
    },
  });

  const { getToken } = useAuth();

  const [signedPages, setSignedPages] = useState<Record<number, number>>({});
  const [generatingDownload, setGeneratingDownload] = useState(false);

  if (fetching || data == null) {
    return (
      <div className="flex items-center justify-center h-full w-full">
        <Spinner className="w-3 h-3 animate-spin text-grey-400" />
      </div>
    );
  }

  const generateDownload = async () => {
    if (generatingDownload) return;

    const token = await getToken();

    if (token == null) return;

    setGeneratingDownload(true);

    document.body.style.cursor = "wait";

    const res = await customerApi.generateFormSignaturePagesDownload({
      formActionId: formActionId,
      token,
    });

    document.body.style.cursor = "default";
    setGeneratingDownload(false);

    if (res.success && res.data != null) {
      const a = document.createElement("a");
      a.href = res.data.downloadUrl;
      a.download = `${data.getFormAction.name.replaceAll(" ", "_")}_signature_pages_${moment().format("YYYY-MM-DD")}.zip`;
      a.click();
      window.URL.revokeObjectURL(res.data.downloadUrl);
    }
  };

  return (
    <div className="flex flex-col h-full bg-grey-700 px-8 pt-6 gap-5">
      <div className="flex flex-row items-center justify-between flex-shrink-0">
        <div className="flex flex-row items-center gap-4">
          <span className="text-grey-200 font-medium">
            {data.getFormAction.name}
          </span>

          {data.getFormAction.status === "sent" && (
            <div className="px-3 py-[3px] text-[#558f40] text-[11px] font-medium bg-[#81CA67] bg-opacity-20 rounded-full ring-1 ring-[#81CA67] ring-opacity-25 shadow-positive-2">
              Ready to sign
            </div>
          )}
          {data.getFormAction.status === "changes_requested" && (
            <div className="px-3 py-[3px] text-[#a48645] text-[11px] font-medium bg-[#caab67] bg-opacity-20 rounded-full ring-1 ring-[#caab67] ring-opacity-25 shadow-sm">
              Changes Requested
            </div>
          )}
          {data.getFormAction.status === "signed" && (
            <div className="flex flex-row gap-1 items-center px-3 py-[3px] text-[#488433] text-[11px] font-medium bg-[#5da045] bg-opacity-20 rounded-full ring-1 ring-[#5da045] ring-opacity-25 shadow-positive-2">
              <CheckCircledIcon /> <span>Signed</span>
            </div>
          )}
        </div>
        <SubmitFormButton data={data.getFormAction} signedPages={signedPages} />
      </div>

      <div className="flex flex-row items-start gap-8 flex-grow overflow-x-auto justify-start lg:justify-center">
        <div className="flex-col h-full flex-1 items-end justify-end pb-6 max-w-[400px] min-w-[300px] flex-shrink-0 hidden lg:flex">
          <div className="text-grey-300 text-sm bg-grey-600 p-3 rounded-lg border border-grey-500 border-opacity-50 font-light">
            {data.getFormAction.status === "signed"
              ? "This form has been signed and added to your visa application."
              : "Once form is reviewed and signed, we'll add it to your visa application. Leave a comment if you have any suggestions for changes."}
          </div>
        </div>

        <div className="flex flex-col h-full min-w-[600px] max-w-[800px] flex-[2] gap-4">
          {data.getFormAction.status !== "signed" && (
            <div className="flex flex-col w-full bg-grey-600 p-3 rounded-lg border border-grey-500 border-opacity-50 gap-4">
              <span className="text-sm text-grey-300 font-light">
                For this form you will need to{" "}
                <span className="font-normal text-grey-200">
                  sign the signature pages with a real pen
                </span>
                . Please print, sign in ink, take a photo or scan and upload it
                back here.{" "}
                <button
                  className={cn(
                    "text-blue font-normal inline-flex flex-row gap-1 items-center cursor-pointer transition-opacity",
                    generatingDownload && "opacity-70 cursor-wait"
                  )}
                  onClick={generateDownload}
                  disabled={generatingDownload}
                >
                  <DownloadIcon />
                  Download pages to sign ({data.getFormAction.pages.length})
                </button>
              </span>

              {data.getFormAction.additionalNotes != null &&
                data.getFormAction.additionalNotes.trim().length > 0 && (
                  <div className="flex flex-col gap-1">
                    <span className="text-grey-300 text-sm">
                      Additional Notes:
                    </span>
                    <div
                      className="text-sm text-grey-300 font-light"
                      dangerouslySetInnerHTML={{
                        __html: data.getFormAction.additionalNotes,
                      }}
                    />
                  </div>
                )}

              <div className="flex flex-row gap-4 overflow-visible pb-2">
                {data.getFormAction.pages.map((pageNum) => (
                  <FileUploadInput
                    key={pageNum}
                    pageNum={pageNum}
                    signedPages={signedPages}
                    setSignedPages={setSignedPages}
                    formActionId={formActionId}
                  />
                ))}
              </div>
            </div>
          )}

          {data.getFormAction.status === "signed" && (
            <div className="flex flex-col w-full bg-grey-600 p-3 rounded-lg border border-grey-500 border-opacity-50 gap-4">
              <span className="text-sm text-grey-300 font-light">
                This form has been signed and added to your visa application.
              </span>
            </div>
          )}

          {data.getFormAction.status === "signed" &&
          data.getFormAction.signedFile ? (
            <FileRenderer url={data.getFormAction.signedFile.presignedUrl} />
          ) : (
            <FileRenderer url={data.getFormAction.baseFile.presignedUrl} />
          )}
        </div>

        <div className="flex flex-col h-full flex-1 items-start pb-6 max-w-[400px] min-w-[300px] flex-shrink-0">
          <CommentRenderer data={data.getFormAction} />
        </div>
      </div>
    </div>
  );
};

export const FormSignatureModal = (props: {
  open: boolean;
  setOpen: (value: boolean) => void;
  formActionId: number;
}) => {
  const { open, setOpen, formActionId } = props;

  return (
    <Modal
      open={open}
      onOpenChange={setOpen}
      title="Review Form"
      borderUnderTitle
      contentClassName="w-[90vw] h-[90vh] max-h-[90vw] max-w-[90vw]"
      childrenClassName="p-0"
    >
      <FormViewer formActionId={formActionId} />
    </Modal>
  );
};
