import { cn } from "@/lib/cn";
import {
  ArrowRightIcon,
  ChatBubbleIcon,
  FileTextIcon,
  Pencil2Icon,
} from "@radix-ui/react-icons";

const CircleIcon = (props: { icon: React.ReactNode; selected?: boolean }) => {
  return (
    <div
      className={cn(
        "w-5 h-5 rounded-full flex items-center justify-center text-grey-300",
        props.selected && "bg-grey-200 text-grey-700"
      )}
    >
      {props.icon}
    </div>
  );
};

export const LetterTimeline = (props: {
  status: "draft" | "review" | "signature";
}) => {
  return (
    <div className="flex flex-row gap-3 items-center w-full justify-center">
      <div className="flex flex-row items-center gap-1.5">
        <CircleIcon
          icon={<FileTextIcon className="w-3 h-3" />}
          selected={props.status === "draft"}
        />
        <div
          className={cn(
            "text-sm text-grey-300",
            props.status === "draft" && "text-grey-200"
          )}
        >
          Draft Letter
        </div>
      </div>

      <ArrowRightIcon className="text-grey-300" />

      <div className="flex flex-row items-center gap-1.5">
        <CircleIcon
          icon={<ChatBubbleIcon className="w-3 h-3" />}
          selected={props.status === "review"}
        />
        <div
          className={cn(
            "text-sm text-grey-300",
            props.status === "review" && "text-grey-200"
          )}
        >
          Review Letter
        </div>
      </div>

      <ArrowRightIcon className="text-grey-300" />

      <div className="flex flex-row items-center gap-1.5">
        <CircleIcon
          icon={<Pencil2Icon className="w-3 h-3" />}
          selected={props.status === "signature"}
        />
        <div
          className={cn(
            "text-sm text-grey-300",
            props.status === "signature" && "text-grey-200"
          )}
        >
          Send for Signature
        </div>
      </div>
    </div>
  );
};
