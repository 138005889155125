// src/index.ts
import mime from "mime";
var env = import.meta.env;
var BACKEND_URL = env.VITE_BACKEND_API_URL ?? "https://backend-1-346o.onrender.com";
var CUSTOMER_BACKEND_URL = env.VITE_CUSTOMER_BACKEND_API_URL ?? "https://customer-backend-prod.onrender.com";
var SYNC_SERVER_BASE_URL = env.VITE_SYNC_SERVER_BASE_URL ?? "sync-server-bzr4.onrender.com";
var adminApi = {
  hocuspocus: {
    get: async (documentName) => {
      try {
        const data = await fetch(
          `https://${SYNC_SERVER_BASE_URL}/html/${documentName}`
        );
        if (!data.ok) {
          return "error fetching html content";
        }
        return await data.text();
      } catch (e) {
        console.error("error fetching html content for id " + documentName, e);
        return "";
      }
    }
  },
  s3: {
    uploadPublicFile: async (params) => {
      const { keyPrefix, file, token } = params;
      const data = await fetch(`${BACKEND_URL}/files/start-public-upload`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({
          key: keyPrefix,
          mime_type: mime.getType(file.name) || "application/octet-stream"
        })
      });
      if (!data.ok) {
        return {
          success: false,
          message: "error getting public upload url"
        };
      }
      const json = await data.json();
      if (json.upload_url == null || json.obj_url == null) {
        return {
          success: false,
          message: "no upload url"
        };
      }
      const uploadData = await fetch(json.upload_url, {
        method: "PUT",
        headers: {
          "Content-Type": mime.getType(file.name) || "application/octet-stream"
        },
        body: file
      });
      if (!uploadData.ok) {
        return {
          success: false,
          message: "error uploading file: " + uploadData.statusText + " " + await uploadData.text()
        };
      }
      return {
        success: true,
        data: { url: json.obj_url }
      };
    }
  },
  backend: {
    fillForm: async (params) => {
      const { id, formType, params: paramsObj, token, addToNode } = params;
      const data = await fetch(`${BACKEND_URL}/forms/create`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({
          form_node_id: parseInt(id),
          form_type: formType,
          data_kwargs: paramsObj,
          add_to_node: addToNode
        })
      });
      if (!data.ok) {
        return {
          success: false,
          message: "error filling form"
        };
      }
      const json = await data.json();
      if (json.file_id == null) {
        return {
          success: false,
          message: "error filling form"
        };
      }
      return {
        success: true,
        data: { fileId: json.file_id }
      };
    },
    uploadFile: async (params) => {
      const {
        getToken,
        file,
        linkType,
        linkId,
        linkSubType = "",
        onProgress
      } = params;
      let token = await getToken();
      if (token == null)
        return { success: false, message: "authentication failed" };
      const requestBody = {
        file_name: file.name,
        mime_type: mime.getType(file.name) || "application/octet-stream"
      };
      if (linkType != null)
        requestBody["link_type"] = `${linkType}/${linkSubType}`;
      if (linkId != null) requestBody["link_record_id"] = parseInt(linkId);
      const startData = await fetch(`${BACKEND_URL}/files/start-upload`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(requestBody)
      });
      if (!startData.ok) {
        return {
          success: false,
          message: "error starting file upload: " + startData.statusText + " " + await startData.text()
        };
      }
      const startJson = await startData.json();
      const presignedUrl = startJson.upload_url;
      const fileId = startJson.file_id;
      if (presignedUrl == null) {
        return {
          success: false,
          message: "error getting presigned url"
        };
      }
      await new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.upload.onprogress = (event) => {
          if (event.lengthComputable) {
            const progress = event.loaded / event.total * 100;
            onProgress?.(progress);
          }
        };
        xhr.onload = () => {
          if (xhr.status >= 200 && xhr.status < 300) {
            resolve();
          } else {
            reject(new Error(`Upload failed with status ${xhr.status}`));
          }
        };
        xhr.onerror = () => reject(new Error("Upload failed"));
        xhr.open("PUT", presignedUrl);
        xhr.setRequestHeader(
          "Content-Type",
          mime.getType(file.name) || "application/octet-stream"
        );
        xhr.send(file);
      });
      token = await getToken();
      if (token == null)
        return { success: false, message: "authentication failed" };
      const finishData = await fetch(`${BACKEND_URL}/files/finish-upload`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({
          file_id: parseInt(fileId)
        })
      });
      if (!finishData.ok) {
        return {
          success: false,
          message: "error finishing file upload"
        };
      }
      return {
        success: true,
        data: { id: fileId }
      };
    },
    getFileUrl: async (params) => {
      const url = new URL(`${BACKEND_URL}/files/${params.id}`);
      if (params.download === true) {
        url.searchParams.set("download", "true");
      }
      const data = await fetch(url, {
        headers: {
          Authorization: `Bearer ${params.token}`
        }
      });
      if (!data.ok) {
        return {
          success: false,
          message: "error fetching file url"
        };
      }
      const json = await data.json();
      if (json.url == null) {
        return {
          success: false,
          message: "no file url"
        };
      }
      return {
        success: true,
        data: { url: json.url }
      };
    },
    duplicateFile: async (params) => {
      const { fileId, linkId, linkType, token } = params;
      const data = await fetch(`${BACKEND_URL}/files/duplicate`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          file_id: parseInt(fileId),
          link_type: linkType,
          link_id: parseInt(linkId)
        })
      });
      if (!data.ok) {
        return {
          success: false,
          message: "error duplicating file"
        };
      }
      const json = await data.json();
      if (json.id == null) {
        return {
          success: false,
          message: "error duplicating file"
        };
      }
      return {
        success: true,
        data: { id: json.id }
      };
    },
    startCompile: async (params) => {
      const { token, exportParserResult, caseId } = params;
      const data = await fetch(`${BACKEND_URL}/compiler/start`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({
          case_id: parseInt(caseId),
          params: exportParserResult,
          partial: params.partial
        })
      });
      if (!data.ok) {
        return {
          success: false,
          message: "error starting compiler"
        };
      }
      const json = await data.json();
      return {
        success: true,
        data: { id: json.job_id }
      };
    },
    generateNode: async (params) => {
      const { caseId, nodeRequest, token } = params;
      try {
        const data = await fetch(`${BACKEND_URL}/cases/${caseId}/node`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
          },
          body: JSON.stringify({
            case_id: caseId,
            ...nodeRequest
          })
        });
        const json = await data.json();
        if (!data.ok) {
          return {
            success: false,
            message: json.detail ?? "Error generating node"
          };
        }
        return {
          success: true,
          data: json
        };
      } catch (e) {
        return {
          success: false,
          message: "Error generating node"
        };
      }
    },
    generateNodeDescription: async (params) => {
      try {
        const res = await fetch(
          `${BACKEND_URL}/node/${params.nodeId}/description`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${params.token}`
            },
            body: JSON.stringify({})
          }
        );
        if (!res.ok) {
          return {
            success: false,
            message: "error generating node description"
          };
        }
        const text = await res.text();
        return {
          success: true,
          data: text
        };
      } catch (e) {
        return {
          success: false,
          message: "error generating node description"
        };
      }
    },
    generateOnboardingFromHubspot: async (params) => {
      const {
        hubspotContactId,
        userEntityId,
        visaClass,
        companyEntityId,
        token
      } = params;
      try {
        const data = await fetch(
          `${BACKEND_URL}/onboardings/generate-user-onboarding-from-hubspot`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`
            },
            body: JSON.stringify({
              hubspot_contact_id: hubspotContactId,
              user_entity_id: parseInt(userEntityId),
              company_entity_id: companyEntityId ? parseInt(companyEntityId) : void 0,
              visa_class: visaClass.replaceAll("_", "-")
            })
          }
        );
        if (!data.ok) {
          return {
            success: false,
            message: "error generating onboarding"
          };
        }
        const json = await data.json();
        return {
          success: true,
          data: json
        };
      } catch (e) {
        return {
          success: false,
          message: "error generating onboarding"
        };
      }
    },
    createCase: async (params) => {
      const {
        beneficiaryId,
        petitionerCompanyId,
        visaClass,
        metadata,
        signerId,
        token,
        rfeOptions,
        caseNotes
      } = params;
      try {
        const data = await fetch(`${BACKEND_URL}/cases/new`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
          },
          body: JSON.stringify({
            beneficiary_id: beneficiaryId,
            company_id: petitionerCompanyId,
            visa_class: visaClass.toLowerCase().replaceAll("_", "-"),
            metadata,
            signer_id: signerId,
            rfe_options: {
              parent_case_id: rfeOptions?.parentCaseId,
              rfe_file_id: rfeOptions?.rfeFileId,
              parent_case_nodes: rfeOptions?.parentCaseNodes
            },
            user_onboarding: (params.userOnboarding ?? []).map((item) => ({
              ...item,
              files: item.files.map((file) => ({
                id: file.id,
                sub_key: file.subKey
              }))
            })),
            company_onboarding: (params.companyOnboarding ?? []).map(
              (item) => ({
                ...item,
                files: item.files.map((file) => ({
                  id: file.id,
                  sub_key: file.subKey
                }))
              })
            ),
            case_strategy_notes: caseNotes
          })
        });
        if (!data.ok) {
          return {
            success: false,
            message: "error creating case"
          };
        }
        const json = await data.json();
        return {
          success: true,
          data: json
        };
      } catch (e) {
        return {
          success: false,
          message: "error creating case"
        };
      }
    },
    generatePressPublisherContent: async (params) => {
      const { entityId, token } = params;
      const data = await fetch(
        `${BACKEND_URL}/entity/press-publisher/${entityId}/similarweb`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      if (!data.ok) {
        return {
          success: false,
          message: "error generating content"
        };
      }
      return {
        success: true,
        data: {}
      };
    },
    generateCompanyBio: async (params) => {
      const { entityId, documentIds, token } = params;
      const data = await fetch(`${BACKEND_URL}/entity/company/bio`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({
          entity_id: parseInt(entityId),
          document_ids: documentIds.map((id) => parseInt(id))
        })
      });
      const json = await data.json();
      if (!data.ok) {
        return {
          success: false,
          message: json.detail ?? "error generating bio"
        };
      }
      return {
        success: true,
        data: json
      };
    },
    generateUserBio: async (params) => {
      const { entityId, documentIds, token } = params;
      const data = await fetch(`${BACKEND_URL}/entity/user/bio`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({
          entity_id: parseInt(entityId),
          document_ids: documentIds.map((id) => parseInt(id))
        })
      });
      const json = await data.json();
      if (!data.ok) {
        return {
          success: false,
          message: json.detail ?? "error generating bio"
        };
      }
      return {
        success: true,
        data: json
      };
    },
    runPassportOCR: async (params) => {
      const { entityId, token } = params;
      const data = await fetch(`${BACKEND_URL}/entity/user/passport-ocr`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({
          entity_id: parseInt(entityId)
        })
      });
      const json = await data.json();
      if (!data.ok) {
        return {
          success: false,
          message: json.detail ?? "error scanning passport"
        };
      }
      return {
        success: true,
        data: json
      };
    },
    getCompanyEIN: async (params) => {
      const { entityId, token } = params;
      const data = await fetch(
        `${BACKEND_URL}/entity/company/${entityId}/ein`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      const json = await data.json();
      if (!data.ok) {
        return {
          success: false,
          message: json.detail ?? "error getting EIN"
        };
      }
      return {
        success: true,
        data: json
      };
    },
    generateI94: async (params) => {
      const { entityId, token } = params;
      let data;
      try {
        data = await fetch(`${BACKEND_URL}/i94`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
          },
          body: JSON.stringify({
            entity_id: parseInt(entityId)
          })
        });
      } catch (e) {
        return {
          success: false,
          message: "something went wrong, please try again."
        };
      }
      if (!data.ok) {
        if (data.status === 400)
          return {
            success: false,
            message: "please ensure entity has a file of type passport."
          };
        return {
          success: false,
          message: "something went wrong, please try again."
        };
      }
      return {
        success: true,
        data: {}
      };
    },
    copyNode: async (params) => {
      const { caseId, nodeId, parentNodeId, token } = params;
      const data = await fetch(`${BACKEND_URL}/cases/${caseId}/copy-node`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({
          node_id: parseInt(nodeId),
          parent_node: parentNodeId == null ? void 0 : parseInt(parentNodeId)
        })
      });
      if (!data.ok) {
        return {
          success: false,
          message: "error copying node"
        };
      }
      const json = await data.json();
      return {
        success: true,
        data: {
          node_id: json.node_id
        }
      };
    },
    rewriteWithAi: async (params) => {
      const { rewriteRequest, token } = params;
      const data = await fetch(`${BACKEND_URL}/editor/rewrite`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(rewriteRequest)
      });
      if (!data.ok) {
        return {
          success: false,
          message: "error rewriting text"
        };
      }
      const json = await data.json();
      return {
        success: true,
        data: json
      };
    },
    generateSuggestedEntitiesForCase: async (params) => {
      const { caseId, token } = params;
      try {
        const data = await fetch(
          `${BACKEND_URL}/entity/suggest/case/${caseId}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`
            }
          }
        );
        if (!data.ok) {
          return {
            success: false,
            message: "error generated suggested entities"
          };
        }
        const json = await data.json();
        return {
          success: true,
          data: {
            numGenerated: json.num_generated
          }
        };
      } catch (e) {
        return {
          success: false,
          message: "error generated suggested entities"
        };
      }
    },
    reviewNode: async (params) => {
      const { nodeId, token } = params;
      const data = await fetch(`${BACKEND_URL}/node/${nodeId}/review`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        }
      });
      if (!data.ok) {
        return {
          success: false,
          message: "error fetching review"
        };
      }
      try {
        const res = await data.json();
        return {
          success: true,
          data: {
            pass: res.success,
            notes: res.content
          }
        };
      } catch (e) {
        return {
          success: false,
          message: "error parsing review"
        };
      }
    },
    reviewLetter: async (params) => {
      const { letterId, token } = params;
      const data = await fetch(`${BACKEND_URL}/letter/${letterId}/review`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        }
      });
      if (!data.ok) {
        return {
          success: false,
          message: "error fetching review"
        };
      }
      try {
        const res = await data.json();
        return {
          success: true,
          data: {
            pass: res.result.length === 0,
            notes: res.result
          }
        };
      } catch (e) {
        return {
          success: false,
          message: "error parsing review"
        };
      }
    },
    generateVCLetterFields: async (params) => {
      const { entityId, documentIds, token } = params;
      const data = await fetch(`${BACKEND_URL}/entity/vc/letter-fields`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          entity_id: parseInt(entityId),
          document_ids: documentIds.map((id) => parseInt(id))
        })
      });
      const json = await data.json();
      if (!data.ok) {
        return {
          success: false,
          message: json.detail ?? "error generating letter fields"
        };
      }
      return {
        success: true,
        data: json
      };
    },
    generateMembershipLetterFields: async (params) => {
      const { entityId, documentIds, token } = params;
      const data = await fetch(
        `${BACKEND_URL}/entity/membership/letter-fields`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            entity_id: parseInt(entityId),
            document_ids: documentIds.map((id) => parseInt(id))
          })
        }
      );
      const json = await data.json();
      if (!data.ok) {
        return {
          success: false,
          message: json.detail ?? "error generating letter fields"
        };
      }
      return {
        success: true,
        data: json
      };
    },
    highlightPdf: async (params) => {
      const { fileId, highlights, token, link_id, link_type, color } = params;
      try {
        const data = await fetch(`${BACKEND_URL}/files/highlight-pdf`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            file_id: parseInt(fileId),
            highlights,
            link_type,
            color,
            link_id: parseInt(link_id)
          })
        });
        if (!data.ok) {
          return {
            success: false,
            message: "something went wrong, please try again."
          };
        }
        const json = await data.json();
        return {
          success: true,
          data: json
        };
      } catch (e) {
        return {
          success: false,
          message: "something went wrong, please try again."
        };
      }
    },
    createOnboarding: async (params) => {
      const { caseId, nodes, token, type } = params;
      try {
        const data = await fetch(`${BACKEND_URL}/onboardings/create`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            case_id: caseId,
            type,
            nodes
          })
        });
        const json = await data.json();
        if (!data.ok) {
          return {
            success: false,
            message: json.detail ?? "error creating onboarding"
          };
        }
        return {
          success: true,
          data: json
        };
      } catch (e) {
        return {
          success: false,
          message: "something went wrong, please try again."
        };
      }
    },
    updateOnboarding: async (params) => {
      const { onboardingId, nodeUpdates, token } = params;
      try {
        const data = await fetch(`${BACKEND_URL}/onboardings/update`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            onboarding_id: onboardingId,
            node_updates: nodeUpdates
          })
        });
        const json = await data.json();
        if (!data.ok) {
          return {
            success: false,
            message: json.detail ?? "error updating onboarding"
          };
        }
        return {
          success: true,
          data: json
        };
      } catch (e) {
        return {
          success: false,
          message: "something went wrong, please try again."
        };
      }
    },
    generateLetter: async (params) => {
      const { caseId, name, type, autogenKwargs, token } = params;
      try {
        const data = await fetch(`${BACKEND_URL}/cases/${caseId}/letter`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
          },
          body: JSON.stringify({
            name,
            type,
            autogen_kwargs: autogenKwargs
          })
        });
        const json = await data.json();
        if (!data.ok) {
          return {
            success: false,
            message: json.detail ?? "Error generating letter"
          };
        }
        return {
          success: true,
          data: json
        };
      } catch (e) {
        return {
          success: false,
          message: "Error generating letter"
        };
      }
    },
    sendLetterRequests: async (params) => {
      const { letterId, token } = params;
      try {
        const data = await fetch(
          `${BACKEND_URL}/letter/${letterId}/send-requests`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );
        const json = await data.json();
        if (!data.ok) {
          return {
            success: false,
            message: json.detail ?? "Error sending all requests"
          };
        }
        return {
          success: true
        };
      } catch (e) {
        return {
          success: false,
          message: "something went wrong, please try again."
        };
      }
    },
    resetLetterRequests: async (params) => {
      const { letterId, token } = params;
      try {
        const data = await fetch(
          `${BACKEND_URL}/letter/${letterId}/reset-requests`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );
        const json = await data.json();
        if (!data.ok) {
          return {
            success: false,
            message: json.detail ?? "Error resetting requests"
          };
        }
        return {
          success: true
        };
      } catch (e) {
        return {
          success: false,
          message: "something went wrong, please try again."
        };
      }
    },
    sendLetterAction: async (params) => {
      const { letterActionId, token } = params;
      try {
        const data = await fetch(
          `${BACKEND_URL}/letter-action/${letterActionId}/send`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );
        const json = await data.json();
        if (!data.ok) {
          return {
            success: false,
            message: json.detail ?? "Error sending letter request"
          };
        }
        return {
          success: true
        };
      } catch (e) {
        return {
          success: false,
          message: "something went wrong, please try again."
        };
      }
    },
    approveLetterAction: async (params) => {
      const { letterActionId, token } = params;
      try {
        const data = await fetch(
          `${BACKEND_URL}/letter-action/${letterActionId}/approve`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );
        const json = await data.json();
        if (!data.ok) {
          return {
            success: false,
            message: json.detail ?? "Error approving letter request"
          };
        }
        return {
          success: true
        };
      } catch (e) {
        return {
          success: false,
          message: "something went wrong, please try again."
        };
      }
    },
    changeEmail: async (params) => {
      const { email, token, userId } = params;
      const data = await fetch(`${BACKEND_URL}/users/change-email`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({
          new_email: email,
          user_id: userId
        })
      });
      if (!data.ok) {
        return {
          success: false,
          message: "error changing email"
        };
      }
      return {
        success: true,
        data: {}
      };
    },
    generateCaseStrategyFromOnboarding: async (params) => {
      const { caseId, token } = params;
      const data = await fetch(`${BACKEND_URL}/cases/${caseId}/case-strategy`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        }
      });
      if (!data.ok) {
        return {
          success: false,
          message: "error generating case strategy"
        };
      }
      const body = await data.text();
      return {
        success: true,
        data: body
      };
    },
    createVersion: async (params) => {
      const {
        caseId,
        name,
        labels,
        partial,
        requests,
        token,
        exportResult,
        changelogHtml
      } = params;
      const body = JSON.stringify({
        case_id: parseInt(caseId),
        version_name: name,
        labels,
        partial,
        params: exportResult,
        changelog_html: changelogHtml,
        requests: requests.map((request) => ({
          request_type: request.type.toLowerCase(),
          recipient_type: request.recipientType.toLowerCase()
        }))
      });
      const data = await fetch(`${BACKEND_URL}/compiler/create-version`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        },
        body
      });
      if (!data.ok) {
        return {
          success: false,
          message: "error creating request"
        };
      }
      const json = await data.json();
      return {
        success: true,
        data: {
          id: json.job_id
        }
      };
    },
    generateSignedLetter: async (params) => {
      const { letterId, token } = params;
      try {
        const data = await fetch(`${BACKEND_URL}/compiler/letter/${letterId}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
          }
        });
        if (!data.ok) {
          return {
            success: false,
            message: "error generating signed letter"
          };
        }
        return {
          success: true,
          message: "letter generated successfully"
        };
      } catch (e) {
        return {
          success: false,
          message: "error generating signed letter"
        };
      }
    },
    getHubspotDeals: async (params) => {
      const { token, skipCache = false } = params;
      const url = new URL(`${BACKEND_URL}/hubspot/deals`);
      if (skipCache) {
        url.searchParams.set("skip_cache", "true");
      }
      const data = await fetch(url.toString(), {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        }
      });
      if (!data.ok) {
        return {
          success: false,
          message: "error getting hubspot deals " + data.statusText
        };
      }
      const json = await data.json();
      return {
        success: true,
        data: {
          deals: json
        }
      };
    }
  }
};
var customerApi = {
  uploadRequestFile: async (params) => {
    const { token, file, requestId } = params;
    const startData = await fetch(
      `${BACKEND_URL}/customer/upload-request/start-upload`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({
          upload_request_id: requestId,
          file_name: file.name,
          mime_type: mime.getType(file.name) || "application/octet-stream"
        })
      }
    );
    if (!startData.ok) {
      return {
        success: false,
        message: "error starting file upload: " + startData.statusText + " " + await startData.text()
      };
    }
    const startJson = await startData.json();
    const presignedUrl = startJson.upload_url;
    const fileId = startJson.file_id;
    if (presignedUrl == null) {
      return {
        success: false,
        message: "error getting presigned url"
      };
    }
    const uploadData = await fetch(presignedUrl, {
      method: "PUT",
      headers: {
        "Content-Type": mime.getType(file.name) || "application/octet-stream"
      },
      body: file
    });
    if (!uploadData.ok) {
      return {
        success: false,
        message: "error uploading file: " + uploadData.statusText + " " + await uploadData.text()
      };
    }
    const finishData = await fetch(
      `${BACKEND_URL}/customer/upload-request/finish-upload`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({
          file_id: parseInt(fileId)
        })
      }
    );
    if (!finishData.ok) {
      return {
        success: false,
        message: "error finishing file upload"
      };
    }
    return {
      success: true
    };
  },
  getLetterAction: async (params) => {
    const { letterActionId, token } = params;
    try {
      const data = await fetch(
        `${CUSTOMER_BACKEND_URL}/letters/get-letter-action`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            letter_action_id: letterActionId,
            token
          })
        }
      );
      const json = await data.json();
      if (!data.ok) {
        return {
          success: false,
          message: data.status.toString()
        };
      }
      return {
        success: true,
        data: {
          id: json.id,
          type: json.type,
          letterId: json.letter_id,
          externalEmail: json.external_email,
          externalName: json.external_name,
          status: json.status,
          beneficiaryName: json.beneficiary_name,
          additionalNotes: json.additional_notes
        }
      };
    } catch (e) {
      return {
        success: false,
        message: "Error getting letter action"
      };
    }
  },
  submitLetterAction: async (params) => {
    const { letterActionId, token, action } = params;
    try {
      const data = await fetch(`${CUSTOMER_BACKEND_URL}/letters/submit`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          letter_action_id: letterActionId,
          token,
          action
        })
      });
      if (!data.ok) {
        return {
          success: false,
          message: "error requesting changes"
        };
      }
      return {
        success: true
      };
    } catch (e) {
      return {
        success: false,
        message: "error requesting changes"
      };
    }
  },
  resendExternalUrl: async (params) => {
    const { letterActionId, token } = params;
    try {
      const data = await fetch(
        `${CUSTOMER_BACKEND_URL}/letters/resend-external-url`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            letter_action_id: letterActionId,
            token
          })
        }
      );
      const json = await data.json();
      if (!data.ok) {
        return {
          success: false,
          message: "error resending link"
        };
      }
      return {
        success: true,
        data: {
          email: json.email
        }
      };
    } catch (e) {
      return {
        success: false,
        message: "error resending link"
      };
    }
  },
  getFollowupAction: async (params) => {
    const { letterActionId, token } = params;
    try {
      const data = await fetch(
        `${CUSTOMER_BACKEND_URL}/letters/follow-up-action?letter_action_id=${letterActionId}&token=${token}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json"
          }
        }
      );
      const json = await data.json();
      if (!data.ok) {
        return {
          success: false,
          message: "error getting follow-up action"
        };
      }
      return {
        success: true,
        data: {
          letterActionId: json.letter_action_id,
          token: json.token
        }
      };
    } catch (e) {
      return {
        success: false,
        message: "error getting follow-up action"
      };
    }
  },
  getLetterActionRecipientName: async (params) => {
    const { letterActionId } = params;
    try {
      const data = await fetch(
        `${CUSTOMER_BACKEND_URL}/letters/get_letter_action_recipient_name/${letterActionId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json"
          }
        }
      );
      const json = await data.json();
      if (!data.ok) {
        return {
          success: false,
          message: "error getting recipient name"
        };
      }
      return {
        success: true,
        data: {
          name: json.name
        }
      };
    } catch (e) {
      return {
        success: false,
        message: "error getting recipient name"
      };
    }
  },
  generateFormSignaturePagesDownload: async (params) => {
    const { formActionId, token } = params;
    try {
      const data = await fetch(
        `${CUSTOMER_BACKEND_URL}/forms/generate-form-signature-pages/${formActionId}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      if (!data.ok) {
        return {
          success: false,
          message: "error generating signature pages"
        };
      }
      const blob = await data.blob();
      const downloadUrl = window.URL.createObjectURL(blob);
      return {
        success: true,
        data: {
          downloadUrl
        }
      };
    } catch (e) {
      return {
        success: false,
        message: "error generating signature pages"
      };
    }
  }
};
export {
  adminApi,
  customerApi
};
