import { getURLPreview } from "@/lib/queries";
import { ExternalLinkIcon } from "@radix-ui/react-icons";
import { useQuery } from "urql";

export const UrlPreview = (props: { url: string }) => {
  const [{ data }] = useQuery({
    query: getURLPreview,
    variables: { url: props.url },
  });

  return (
    <button
      className="bg-blue-light text-blue rounded-sm px-2 justify-between flex flex-row items-center gap-2 text-sm w-full"
      onClick={() => window.open(props.url, "_blank")}
    >
      <span className="text-nowrap text-ellipsis overflow-hidden w-[90%] text-left">
        {data?.linkPreview?.title ?? props.url}
      </span>
      <ExternalLinkIcon className="w-3 h-3" />
    </button>
  );
};
