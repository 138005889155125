import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import {
  ErrorComponentProps,
  RouterProvider,
  createRouter,
  useRouter,
} from "@tanstack/react-router";
import { Toaster } from "react-hot-toast";
import { ClerkLoaded, ClerkProvider, useAuth } from "@clerk/clerk-react";
import { Spinner, Theme } from "@radix-ui/themes";
import * as Sentry from "@sentry/react";
import { pdfjs } from "react-pdf";

import "./index.css";

// Import the generated route tree
import { routeTree } from "./routeTree.gen";
import { useAdminFunctions } from "./lib/hooks";
import { GraphqlProvider } from "./providers/graphql";
import { useClient } from "urql";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReloadIcon } from "@radix-ui/react-icons";
import { Button } from "./components/button";

const queryClient = new QueryClient();

const PUBLISHABLE_KEY = import.meta.env.VITE_CLERK_PUBLISHABLE_KEY;

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.mjs",
  import.meta.url
).toString();

const isProd =
  import.meta.env.PROD || window.location.hostname === "lighthousehq.com";

const ErrorComponent = (props: ErrorComponentProps) => {
  const router = useRouter();

  useEffect(() => {
    Sentry.captureException(props.error, {
      level: "fatal",
      extra: {
        error: props.error,
        trace: props.error.stack,
        url: window.location.href,
        componentStack: props.info?.componentStack,
      },
    });
  }, [props.error]);

  return (
    <div className="w-screen h-screen flex flex-col items-center justify-center">
      <div className="text-lg">Something went wrong </div>
      <div className="mt-2 mb-4 max-h-[400px] w-[600px] text-grey-300 text-sm text-center">
        The Lighthouse team has been notified of this error -- we're working on
        it. In the meantime, reload the page to continue.
      </div>

      <Button onClick={() => router.invalidate()} variant="primary">
        <ReloadIcon />
        Reload
      </Button>
    </div>
  );
};

const router = createRouter({
  routeTree,
  defaultPendingComponent: () => (
    <div className="w-screen h-screen flex items-center justify-center bg-grey-800">
      <Spinner />
    </div>
  ),
  defaultErrorComponent: ErrorComponent,
  defaultNotFoundComponent: () => <div>404</div>,
  context: {
    auth: undefined!, // This will be set after we wrap the app in an Provider
    client: undefined!, //  This will be set after we wrap the app in an Provider
  },
});

if (isProd) {
  Sentry.init({
    dsn: "https://cc04c536066027236f52e4ef1fc0f866@o4507013422776320.ingest.us.sentry.io/4508162381643776",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
        networkDetailAllowUrls: [
          "https://customer-backend-prod.onrender.com/graphql",
        ],
      }),
      Sentry.tanstackRouterBrowserTracingIntegration(router),
      Sentry.captureConsoleIntegration({
        levels: ["error"],
      }),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 1,
    replaysOnErrorSampleRate: 1.0,
  });
}

if (!PUBLISHABLE_KEY) {
  throw new Error("Missing CLERK_PUBLISHABLE_KEY");
}

declare module "@tanstack/react-router" {
  interface Register {
    router: typeof router;
  }
}

const InnerApp = () => {
  const auth = useAuth();
  const client = useClient();
  useAdminFunctions();

  return <RouterProvider router={router} context={{ auth, client }} />;
};

// Render the app
const rootElement = document.getElementById("app")!;
if (!rootElement.innerHTML) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <React.StrictMode>
      <Sentry.ErrorBoundary
        fallback={({ error, resetError }) => (
          <ErrorComponent error={error as Error} reset={resetError} />
        )}
      >
        <Theme
          appearance="light"
          accentColor="gray"
          panelBackground="translucent"
        >
          <ClerkProvider publishableKey={PUBLISHABLE_KEY}>
            <ClerkLoaded>
              <GraphqlProvider>
                <QueryClientProvider client={queryClient}>
                  <InnerApp />
                </QueryClientProvider>
              </GraphqlProvider>
              <Toaster
                position="top-right"
                toastOptions={{
                  duration: 5000,
                }}
                containerStyle={{
                  marginTop: "10px",
                  marginRight: "10px",
                }}
              />
            </ClerkLoaded>
          </ClerkProvider>
        </Theme>
      </Sentry.ErrorBoundary>
    </React.StrictMode>
  );
}
