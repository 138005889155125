import { CreateAdditionalWorkExperienceNodeOption } from "@/components/createAdditionalWorkExperienceNodeOption";
import { Field, OnboardingForm, SubkeyUploadArea } from "@/components/form";
import { TextArea } from "@/components/inputs";
import { useOnboardingNodeData } from "@/lib/hooks";
import { useOnboarding } from "@/providers/onboardingProvider";

export const Judging = () => {
  const { currentOnboardingNodeId } = useOnboarding();
  const { data, doSetData } = useOnboardingNodeData(
    currentOnboardingNodeId ?? -1
  );

  return (
    <OnboardingForm heading="Your work experience">
      <p className="text-[12px] text-grey-300 mr-12 mt-2">
        This is evidence that you’ve been requested and confirmed participating
        as a judge for the work of others in your field or related industry
        event.
      </p>
      <Field
        id="supportingDocuments"
        label="Provide supporting documents regarding the judging events you participated in."
        description="Lighthouse will review all documents to check if it they're relevant."
      >
        <SubkeyUploadArea
          onboardingNodeId={currentOnboardingNodeId ?? -1}
          subKey="supportingDocuments"
        />
      </Field>

      <Field
        id="additionalComments"
        label="Additional comments"
        description="If you have any additional comments, that you think will be useful for us working on this criteria, please add them here."
      >
        <TextArea
          value={data.additionalComments?.value as string}
          onChange={(val) => doSetData("additionalComments", val)}
          className="w-full"
          charLimit={500}
        />
      </Field>

      {currentOnboardingNodeId != null && (
        <CreateAdditionalWorkExperienceNodeOption
          onboardingNodeId={currentOnboardingNodeId}
          type="judging"
          name="judging"
          description="If you have any other judging experiences that you'd like to add, please add them here."
        />
      )}
    </OnboardingForm>
  );
};
