export const LockIcon = () => (
  <svg
    width="13"
    height="14"
    viewBox="0 0 13 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1780_46679)">
      <path
        d="M6.5 8.625C7.06091 8.625 7.51562 8.17029 7.51562 7.60938C7.51562 7.04846 7.06091 6.59375 6.5 6.59375C5.93909 6.59375 5.48438 7.04846 5.48438 7.60938C5.48438 8.17029 5.93909 8.625 6.5 8.625Z"
        stroke="#2C2C2C"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.5 8.625V9.84375"
        stroke="#2C2C2C"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.5625 4.96875H2.4375C2.21313 4.96875 2.03125 5.15063 2.03125 5.375V11.0625C2.03125 11.2869 2.21313 11.4688 2.4375 11.4688H10.5625C10.7869 11.4688 10.9688 11.2869 10.9688 11.0625V5.375C10.9688 5.15063 10.7869 4.96875 10.5625 4.96875Z"
        fill="#111111"
        fillOpacity="0.24"
        stroke="#2C2C2C"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.46875 4.96875V3.34375C4.46875 2.80503 4.68276 2.28837 5.06369 1.90744C5.44462 1.52651 5.96128 1.3125 6.5 1.3125C7.03872 1.3125 7.55538 1.52651 7.93631 1.90744C8.31724 2.28837 8.53125 2.80503 8.53125 3.34375V4.96875"
        stroke="#2C2C2C"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1780_46679">
        <rect
          width="13"
          height="13"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);
